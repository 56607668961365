/* global JSX */
import React from 'react';
import { Redirect, Route, RouteComponentProps, useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/app/hooks';
import { selectUser } from 'src/slices/userSlice';

interface IProps {
	Component: React.FC<RouteComponentProps>;
	path: string;
	exact?: boolean;
}

export default function AuthenticatedRoute({ Component, path, exact = false }: IProps): JSX.Element {
	const { pathname, search } = useLocation();
	const user = useAppSelector(selectUser);
	return (
		<Route
			exact={exact}
			path={path}
			render={(props: RouteComponentProps) =>
				user.isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect to={`/login${pathname === '/' ? '' : `?redirect=${pathname}${search}`}`} />
				)
			}
		/>
	);
}

AuthenticatedRoute.defaultProps = { exact: false };
