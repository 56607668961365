/* global localStorage */
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { env, formatUser, getAPI, isValidUrl } from 'src/Libs/helper';
import {
	ARRAYOFFSET,
	MAX_SHARED_SECRET_LENGTH,
	MAX_WEBHOOK_LENGTH,
	MINARRAYLEN,
	MIN_SHARED_SECRET_LENGTH,
	MODAL_OPACITY,
} from 'src/Libs/magicConst';
import { webhookEventTypes, webhookInterface } from 'src/Libs/types';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import RingsIcon from 'src/assets/rings.svg';
import SeeAdditionalIcon from 'src/assets/see-additional.svg';
import XMark from 'src/assets/x-mark.svg';
import config from 'src/config';
import NavbarPostLogin from 'src/features/Shared/NavbarPostLogin';
import SceneTitle from 'src/features/Shared/SceneTitle';
import Sidebar from 'src/features/Shared/Sidebar';
import { selectCompany, setWebhooks } from 'src/slices/companySlice';
import { selectUser } from 'src/slices/userSlice';

import styles from './Webhook.module.css';

export const Webhook: React.FC = () => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectUser);
	const company = useAppSelector(selectCompany);
	const history = useHistory();
	// error
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	// success
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	// loading
	const [isLoading, setIsLoading] = useState(false);
	const [newWebhookUrl, setNewWebhookUrl] = useState<string | null>(null);
	const [newWebhookSecret, setNewWebhookSecret] = useState<string | null>(null);
	const [newWebhookEventTypes, setNewWebhookEventTypes] = useState<webhookEventTypes[]>([]);
	// create webhook
	const [isCreateWebhook, setIsCreateWebhook] = useState<boolean>(false);

	const accessToken: string =
		localStorage[
			`CognitoIdentityServiceProvider.${config[env].cognito.APP_CLIENT_ID}.${formatUser(user.userId)}.accessToken`
		];

	// load webhook
	const loadWebhook = (): void => {
		axios
			.get(getAPI('auth', 'webhooks'), { headers: { Authorization: `Bearer ${accessToken}` } })
			.then((res) => {
				dispatch(setWebhooks({ webhooks: res.data }));
				// when called from update, want to turn off edit button here
				// otherwise, there is a small glitch in changing back to old value
				// if setEditButton(false) from other function
			})
			.catch(() => {
				setIsLoading(false);
				setIsError(true);
			});
	};
	useEffect(() => {
		loadWebhook();
	}, []);

	const isCreateWebhookDisabled = (): boolean =>
		isDisabled ||
		newWebhookUrl === null ||
		!isValidUrl(newWebhookUrl) ||
		(newWebhookSecret !== null && newWebhookSecret !== '' && newWebhookSecret.length < MIN_SHARED_SECRET_LENGTH) ||
		newWebhookEventTypes.length === 0;
	// create webhook
	const createWebhook = (): void => {
		setIsLoading(true);
		setIsSuccess(false);
		setIsError(false);
		setSuccessMessage('');
		setErrorMessage('');
		axios
			.post(
				getAPI('auth', 'webhooks'),
				{
					webhookUrl: newWebhookUrl,
					sharedSecret: newWebhookSecret,
					eventTypes: newWebhookEventTypes,
				},
				{ headers: { Authorization: `Bearer ${accessToken}` } }
			)
			.then(() => {
				setIsDisabled(true);
				setIsLoading(false);
				setIsSuccess(true);
				setSuccessMessage('Webhook Created Successfully');
				loadWebhook();
			})
			.catch(() => {
				setIsLoading(false);
				setIsSuccess(false);
				setIsError(true);
				setErrorMessage('Unable to create Webhook. Please try again later.');
			});
	};

	const closeCreateWebhookModal = () => {
		setIsCreateWebhook(false);
		setNewWebhookEventTypes([]);
		setNewWebhookSecret(null);
		setNewWebhookUrl(null);
		setIsDisabled(false);
	};

	const appendEventType = (eventType: webhookEventTypes) => {
		setNewWebhookEventTypes([...newWebhookEventTypes, eventType]);
	};
	const removeEventType = (eventType: webhookEventTypes) => {
		const index = newWebhookEventTypes.indexOf(eventType);
		setNewWebhookEventTypes([
			...newWebhookEventTypes.slice(0, index),
			...newWebhookEventTypes.slice(index + 1, newWebhookEventTypes.length),
		]);
	};

	const renderCreateWebhook = () => (
		<Modal
			isOpen={isCreateWebhook}
			className={styles.webhook__modal}
			onRequestClose={() => {
				closeCreateWebhookModal();
			}}
			style={{ overlay: { backgroundColor: `rgba(0, 0, 0, ${MODAL_OPACITY})` } }}
			overlayClassName={styles.modal__overlay}
			ariaHideApp={false}
		>
			<input
				type="image"
				src={XMark}
				className={styles.x__mark}
				alt="Placeholder"
				onClick={() => closeCreateWebhookModal()}
			/>
			<form className={styles.create__form}>
				<div className={styles.create__form__header}>Create Webhook</div>
				<div className={styles.explain}>
					You can view the&nbsp;
					<a href="https://docs.neuraml.ai" target="_blank" rel="noreferrer">
						&nbsp;documentation
					</a>
					&nbsp;for additional information.
					<br />
					<br />
					If a Shared Secret is not specified, one will be created and displayed below. <br />
				</div>
				<div className={cx(styles.field__container)}>
					<div className={cx(styles.field__label)}>Webhook URL:</div>
					<input
						type="url"
						placeholder="https://"
						value={newWebhookUrl ?? ''}
						className={cx(styles.field__input)}
						onChange={(e) => {
							if (e.target.value.length < MAX_WEBHOOK_LENGTH && !e.target.value.includes(' ')) {
								setNewWebhookUrl(e.target.value);
							}
						}}
					/>
				</div>
				<div className={cx(styles.field__container)}>
					<div>
						<div className={cx(styles.field__label)}>Shared Secret:</div>
						<div
							className={cx(styles.field__label__sub)}
						>{`(Min ${MIN_SHARED_SECRET_LENGTH} Characters)`}</div>
					</div>
					<input
						type="url"
						placeholder=""
						value={newWebhookSecret ?? ''}
						className={cx(styles.field__input)}
						onChange={(e) => {
							if (e.target.value.length < MAX_SHARED_SECRET_LENGTH && !e.target.value.includes(' ')) {
								setNewWebhookSecret(e.target.value);
							}
						}}
					/>
				</div>
				<div className={styles.field__input}>Event Types:</div>
				<div className={styles.radio__items__container}>
					<div
						className={styles.radio__item__container}
						onClick={() => {
							if (!newWebhookEventTypes.includes('issues')) appendEventType('issues');
							else removeEventType('issues');
						}}
					>
						<input
							type="checkbox"
							checked={newWebhookEventTypes.includes('issues')}
							className={styles.radio__item}
							onChange={(e) => {
								if (e.target.checked) appendEventType('issues');
								else removeEventType('issues');
							}}
						/>
						<div className={styles.radio__item__label}>Issues</div>
					</div>
					<div
						className={styles.radio__item__container}
						onClick={() => {
							if (!newWebhookEventTypes.includes('issues')) appendEventType('issues');
							else removeEventType('issues');
						}}
					>
						<input
							type="checkbox"
							checked={newWebhookEventTypes.includes('issues')}
							className={styles.radio__item}
							onChange={(e) => {
								if (e.target.checked) appendEventType('issues');
								else removeEventType('issues');
							}}
						/>
						<div className={styles.radio__item__label}>Issues</div>
					</div>
				</div>
				<div className={styles.webhook__button__container}>
					<button
						type="button"
						className={cx(styles.webhook__button, {
							[styles.webhook__button__disabled]: isCreateWebhookDisabled(),
						})}
						disabled={isCreateWebhookDisabled()}
						onClick={() => {
							createWebhook();
						}}
					>
						{isLoading ? <img src={RingsIcon} alt="loading" /> : 'Create Webhook'}
					</button>
				</div>
			</form>
		</Modal>
	);

	const displayWebhookTable = () => (
		<div className={styles.table}>
			<div className={styles.table__header}>
				<div>
					<div className={styles.table__header__title}>Endpoints</div>
					<div className={styles.table__header__subtitle}>
						Webhooks are used to comunicate with&nbsp;{company.companyName}. <br />
					</div>
				</div>
				<button
					type="button"
					// className={styles.apikey__button}
					onClick={() => {
						setIsCreateWebhook(true);
					}}
				>
					<div className={styles.addButton}>
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9.99996 4.16675V15.8334M4.16663 10.0001H15.8333"
								strokeWidth="1.66667"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<div>new endpoint</div>
					</div>
				</button>
			</div>
			<div className={styles.columns__container}>
				<div className={cx(styles.col, styles.col__webhook__name)}>URL</div>
				<div className={cx(styles.col, styles.col__webhook)}>Type</div>
				<div className={cx(styles.col, styles.col__webhook)}>Error Rate</div>
				<div className={cx(styles.col, styles.col__status)}>Status</div>
			</div>
			<div>
				{company.webhooks !== null &&
					company.webhooks !== undefined &&
					company.webhooks.length > MINARRAYLEN &&
					company.webhooks.map((webhook: webhookInterface, index: number) => (
						<div
							key={`developer key${index}`}
							className={cx(styles.record, {
								[styles.record__last]: index === company.webhooks.length - ARRAYOFFSET,
							})}
							onClick={() => {
								history.push(`webhooks/${webhook.webhookId}`);
							}}
						>
							<div className={cx(styles.row__cell, styles.row__webhook__name)}>{webhook.webhookUrl}</div>
							<div className={cx(styles.row__cell, styles.row__webhook)}>
								{webhook.eventTypes.join(', ')}
							</div>
							<div className={cx(styles.row__cell, styles.row__webhook)}>{webhook.errorRate}%</div>
							<div className={cx(styles.row__cell, styles.row__status)}>
								<div
									className={cx(
										styles.row__status__text,
										{ [styles.row__status__enabled]: webhook.active },
										{ [styles.row__status__disabled]: !webhook.active }
									)}
								>
									{webhook.active ? 'Enabled' : 'Disabled'}
								</div>
								<img
									src={SeeAdditionalIcon}
									alt="see additional information"
									className={styles.additional__icon}
								/>
							</div>
						</div>
					))}
			</div>
		</div>
	);

	return (
		<div className={styles.root}>
			<NavbarPostLogin />
			{isError && (
				<Alert severity="error" className={styles.alert__message}>
					{errorMessage}
				</Alert>
			)}
			{isSuccess && (
				<Alert severity="success" className={styles.alert__message}>
					{successMessage}
				</Alert>
			)}

			<div className={styles.root_body}>
				<Sidebar />
				<div className={styles.component__container}>
					<SceneTitle title="Webhooks" />

					{displayWebhookTable()}
					{isCreateWebhook && renderCreateWebhook()}
				</div>
			</div>
		</div>
	);
};

export default Webhook;
