import { ChevronDown, ChevronUp, Eye, LayoutGrid, Radio, Text } from 'lucide-react';
import React, { ReactElement, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { selectFlow, setSidebarFilters } from 'src/slices/flowSlice';
import styles from './Sidebar.module.css';

interface ModelType {
	name: string;
	icon: ReactElement;
	subcategories: string[];
}

const Sidebar: React.FC = () => {
	const [openModelTypes, setOpenModelTypes] = useState<Set<string>>(new Set());
	const dispatch = useAppDispatch();
	const flowState = useAppSelector(selectFlow);

	const modelTypes: ModelType[] = [
		{
			name: 'Multimodal',
			icon: <LayoutGrid />,
			subcategories: ['Image-to-Text', 'Text-to-Image', 'Text-to-Video'],
		},
		{
			name: 'Vision',
			icon: <Eye />,
			subcategories: [
				'Depth Estimation',
				'Image Classification',
				'Image Segmentation',
				'Object Detection',
				'Pose Estimation',
			],
		},
		{
			name: 'Audio',
			icon: <Radio />,

			subcategories: ['Text-To-Audio'],
		},
		{
			name: 'Text',
			icon: <Text />,
			subcategories: ['Question Answering', 'Text Detection', 'Text classification'],
		},
	];

	const handleToggle = (modelTypeName: string) => {
		const newOpenModelTypes = new Set(openModelTypes);
		if (newOpenModelTypes.has(modelTypeName)) {
			newOpenModelTypes.delete(modelTypeName);
		} else {
			newOpenModelTypes.add(modelTypeName);
		}
		setOpenModelTypes(newOpenModelTypes);
	};

	return (
		<div className={styles.sidebar__wrapper}>
			<div className={styles.sidebar}>
				<br />
				<div className={styles.button}>Dashboard</div>
				<div className={styles.button}>Bookmarks</div>
				<div className={styles.separator_wrapper}>
					<div className={styles.separator} />
				</div>
				<div className={styles.sectionTitle}>Models</div>

				{modelTypes.map((modelType) => (
					<div className={styles.models} key={modelType.name}>
						<div className={styles.categoryButton} onClick={() => handleToggle(modelType.name)}>
							<div className={styles.category_left}>
								{modelType.icon}
								{modelType.name}
							</div>

							<div>{openModelTypes.has(modelType.name) ? <ChevronUp /> : <ChevronDown />}</div>
						</div>
						{openModelTypes.has(modelType.name) &&
							modelType.subcategories.map((sub) => (
								<div
									key={sub}
									className={styles.subcategory_wrapper}
									onClick={() => {
										if (
											flowState.sidebarFilters !== undefined &&
											flowState.sidebarFilters !== null &&
											flowState.sidebarFilters === sub
										) {
											dispatch(setSidebarFilters({ sidebarFilters: '' }));
										} else {
											dispatch(setSidebarFilters({ sidebarFilters: sub }));
										}
									}}
								>
									<div className={styles.subcategory}>{sub}</div>
								</div>
							))}
					</div>
				))}
				<div className={styles.separator_wrapper}>
					<div className={styles.separator} />
				</div>
				<div className={styles.sectionTitle}>Resources</div>
				<div className={styles.button}>Documentation</div>
				<div className={styles.button}>Slack Channel</div>
			</div>
		</div>
	);
};

export default Sidebar;
