import { Alert } from '@material-ui/lab';
import { Auth } from 'aws-amplify';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppSelector } from 'src/app/hooks';
// temp
import leftarrow from 'src/assets/arrow/leftarrowOutline.png';
import logo from 'src/assets/logo/logo_black.svg';
import rings from 'src/assets/rings.svg';
import { MINARRAYLEN, MINPASSLEN } from 'src/Libs/magicConst';
import { selectUser } from 'src/slices/userSlice';

import styles from './ForgotPassword.module.css';

export const ForgotPassword: React.FC = () => {
	const user = useAppSelector(selectUser);
	const history = useHistory();
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	useEffect(() => {
		if (user.isAuthenticated) {
			// TODO: some kind of message that user is logged in
			history.push('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [codeSent, setCodeSent] = useState(false);
	const [confirmed, setConfirmed] = useState(false);
	const [isConfirming, setIsConfirming] = useState(false);

	const [resetCode, setCode] = useState('');
	const [resetEmail, setEmail] = useState('');
	const [resetPassword, setPassword] = useState('');
	const [confirmResetPassword, setConfirmResetPassword] = useState('');
	// loading
	const [isLoading, setIsLoading] = useState(false);

	const validateCodeForm = (): boolean => resetEmail.length > MINARRAYLEN;
	const validateResetForm = (): boolean =>
		resetCode.length > MINARRAYLEN && resetPassword.length >= MINPASSLEN && resetPassword === confirmResetPassword;

	const handleSendCodeClick = (event: React.FormEvent) => {
		event.preventDefault();
		setIsLoading(true);
		Auth.forgotPassword(resetEmail)
			.then(() => {
				setIsLoading(false);
				setCodeSent(true);
				setIsError(false);
				setErrorMessage('');
			})
			.catch((error) => {
				setIsLoading(false);
				setCodeSent(false);
				setIsError(true);
				setErrorMessage(error.message);
			});
	};

	const handleConfirmClick = (event: React.FormEvent) => {
		event.preventDefault();
		setIsConfirming(true);
		Auth.forgotPasswordSubmit(resetEmail, resetCode, resetPassword)
			.then(() => {
				setConfirmed(true);
				setIsError(false);
				setErrorMessage('');
			})
			.catch((error) => {
				setIsConfirming(false);
				setIsError(true);
				setErrorMessage(error.message);
			});
	};
	const renderRequestCodeForm = () => (
		<div className={styles.container}>
			<div className={styles.header__wrapper}>
				<img src={logo} className={styles.logo} alt="asset" />
				<h1 className={styles.logo__text}> neuraml </h1>
			</div>
			{isError && (
				<Alert severity="error" className={styles.alert__message}>
					{errorMessage}
				</Alert>
			)}
			<form className={styles.form} onSubmit={handleSendCodeClick}>
				<div className={styles.form__header__wrapper}>
					<div className={styles.form__header__title}> Reset Password</div>
					<div className={styles.form__header__text}>Please enter your email address below.</div>
				</div>
				<div className={styles.field__label}> Email </div>
				<input
					type="email"
					placeholder=""
					value={resetEmail}
					className={cx(styles.section__input)}
					onChange={(e) => setEmail(e.target.value)}
					autoComplete="off"
				/>
				<div className={cx(styles.button__container)}>
					<button
						type="submit"
						className={styles.form__button}
						// data-isLoading={isSendingCode}
						disabled={!validateCodeForm()}
					>
						{isLoading ? <img src={rings} alt="loading" /> : 'Send Confirmation'}
					</button>
				</div>
			</form>
		</div>
	);

	const renderConfirmationForm = () => (
		<div className={styles.container}>
			<div className={styles.header__wrapper}>
				<img src={logo} className={styles.logo} alt="asset" />
				<h1 className={styles.logo__text}> neuraml </h1>
			</div>
			{isError && (
				<Alert severity="error" className={styles.alert__message}>
					{errorMessage}
				</Alert>
			)}
			<form className={styles.form} onSubmit={handleConfirmClick}>
				<div className={styles.form__header__wrapper}>
					<div className={styles.form__header__title}> Confirm Email </div>
				</div>
				<div className={styles.field__label}>Please check ({resetEmail}) for the confirmation code.</div>
				<div className={styles.field__label}> Confirmation Code </div>
				<div className={styles.confirmationcode}>
					<input
						type="tel"
						placeholder="######"
						value={resetCode}
						onChange={(e) => setCode(e.target.value)}
						autoComplete="off"
						className={cx(styles.section__input)}
					/>
				</div>
				<div className={styles.field__label}> New Password </div>
				<input
					type="password"
					placeholder="New Password"
					value={resetPassword}
					onChange={(e) => setPassword(e.target.value)}
					autoComplete="off"
					className={cx(styles.section__input)}
				/>
				<div className={styles.field__label}> Confirm New Password </div>
				<input
					id="resetPassword"
					type="password"
					placeholder="Confirm Password"
					value={confirmResetPassword}
					onChange={(e) => setConfirmResetPassword(e.target.value)}
					autoComplete="off"
					className={cx(styles.section__input)}
				/>
				<div className={cx(styles.button__container)}>
					<button
						type="submit"
						// data-isLoading={isConfirming}
						disabled={!validateResetForm()}
						className={styles.form__button}
					>
						{isConfirming ? <img src={rings} alt="loading" /> : 'Confirm'}
					</button>
				</div>
			</form>
		</div>
	);

	const renderSuccessMessage = () => (
		<div className={styles.container}>
			<div className={styles.header__wrapper}>
				<img src={logo} className={styles.logo} alt="asset" />
				<h1 className={styles.logo__text}> neuraml </h1>
			</div>
			<div className={styles.confirm__reset}>
				{/* Just fto center */}
				<p className={styles.success__message}>Your Password Has Been Reset!</p>
				<Link to="/login" className={styles.login__link}>
					<button className={styles.form__button} type="button">
						<img src={leftarrow} alt="left arrow" />
						&nbsp; Login
					</button>
				</Link>
			</div>
		</div>
	);

	return (
		<div>
			{!codeSent ? renderRequestCodeForm() : !confirmed ? renderConfirmationForm() : renderSuccessMessage()}
		</div>
	);
};

export default ForgotPassword;
