import { BroadcastChannel } from 'broadcast-channel';
import config from 'src/config';
import { monthNumToStrInterface } from 'src/Libs/types';

import { MILLSECONDSINSECOND, SECONDSINMINUTE } from './magicConst';

/*
 * Change in different environments
 * 'dev';
 */
export const UNAUTHORIZED = 401;

export const FORBIDDEN = 403;

const getEnv = (): 'local' | 'dev' | 'stage' | 'sandbox' | 'production' => {
	try {
		if (process.env.REACT_APP_ENV === 'prod') {
			return 'production';
		} else if (process.env.REACT_APP_ENV === 'sandbox') {
			return 'sandbox';
		} else if (process.env.REACT_APP_ENV === 'stage') {
			return 'stage';
		} else if (process.env.REACT_APP_ENV === 'local') {
			return 'local';
		}
	} catch {
		return 'dev';
	}
	return 'dev';
};
export const env: 'local' | 'dev' | 'stage' | 'sandbox' | 'production' = getEnv() ?? 'dev';

export const RELEASE = env === 'dev';

export const NUMDECIMAL = 2;

// 500kb
export const MIN_FILE_SIZE = 500_000;

// Max 25 mb
export const MAX_FILE_SIZE = 25_000_000;

export const getData = (s3Location: string | null, nameId: string | null, photos: boolean): string => {
	if (s3Location !== null && nameId !== null) {
		if (env === 'dev') {
			return `https://s3folder${s3Location}/${nameId}.pdf`;
		}
		if (env === 'production' && photos) {
			return `${config.prodSite + s3Location}/${nameId}.pdf`;
		}
	}

	return 'Not Found';
};

export const getDataArg = (fileName: string | null | undefined): string => {
	if (fileName !== null && fileName !== undefined) {
		if (env === 'dev') {
			return `https://s3folder${fileName}`;
		}
		if (env === 'production') {
			return config.prodSite + (fileName ?? '');
		}
	}
	return 'Not Found';
};

interface Message {
	logoutMessage: string;
}

export const logoutChannel: BroadcastChannel<Message> = new BroadcastChannel('logout');

export const MonthNUMTOSTR: monthNumToStrInterface = {
	1: 'Jan',
	2: 'Feb',
	3: 'Mar',
	4: 'Apr',
	5: 'May',
	6: 'Jun',
	7: 'Jul',
	8: 'Aug',
	9: 'Sep',
	10: 'Oct',
	11: 'Nov',
	12: 'Dec',
};
export const formatUser = (userId: string): string => {
	try {
		return userId.split('_')[1];
	} catch {
		throw new Error('Unknown');
	}
};
export const getAPI = (application: 'auth' | 'team' | 'apprunner', endpoint: string, wss = false): string => {
	let root_url;
	if (env === 'local') {
		if (application === 'apprunner') root_url = 'http://localhost:8080';
		else root_url = `https://dev.api.neuraml.ai`;
	} else {
		root_url = `https://${env}.api.neuraml.ai`;
	}
	return `${root_url}${config.apiGateway[application][endpoint]}`;
};
// `${
// 	(wss ? 'wss://' : 'https://') +
// 	(env === 'production' ? 'production' : env === 'sandbox' ? 'sandbox' : env === 'stage' ? 'stage' : 'dev') +
// 	(wss ? '-wss' : '')
// }.neuraml.ai${config.apiGateway[application][endpoint]}`;

export const parseJWT = (token: string): any => {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
			.join('')
	);

	return JSON.parse(jsonPayload);
};

// Useful whenver user tries to access any resource not allowed while not logged in
export const routeUser = (authenticated: boolean, pathname: string, search: string, history: any): void => {
	if (!authenticated) {
		history.push(`/login?redirect=${pathname}${search}`); // May want to return Redirect
	}
};

export const formatCognitoError = (e: string): string => {
	const k = 'failed with error ';

	if (e.includes(k)) {
		return e.split(k)[1];
	}

	return e;
};

export const entityTypeList: string[] = [
	'C-Corp',
	'S-Corp',
	'B-Corp',
	'LLC',
	'LLP',
	'Sole Proprietorship',
	'501 (c)(3) Org',
].sort();

export const camelToSnakeCase = (str: string): string =>
	// add check in case run multiple times
	str.includes('_') ? str : str.replace(/[A-Z]/g, (letter): string => `_${letter.toLowerCase()}`);
// const camelToSnakeCase = (str) =>str.includes('_') ? str : str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeToCamelCase = (str: string): string =>
	// add check because if run twice -> first_name -> firstName -> firstname
	str.includes('_')
		? str
				.toLowerCase()
				.replace(/([-_]\w)/g, (group: string) => group.toUpperCase().replace('-', '').replace('_', ''))
		: str;

// const snakeToCamelCase = (str) => str.includes('_')? str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', '')): str;

export const convertObjectCamelSnake = (obj: Object | any[], f: (s: string) => string): any => {
	if (obj !== null && obj !== undefined && obj.constructor === Object) {
		const ret: any = {};
		Object.entries(obj).map((k) => {
			const key = f(k[0]);
			ret[key] = k[1];
			if (k[1] !== null && k[1] !== undefined && k[1].constructor === Object) {
				ret[key] = convertObjectCamelSnake(k[1], f);
			} else if (k[1] !== null && k[1] !== undefined && k[1].constructor === Array) {
				ret[key] = k[1].map((d) => convertObjectCamelSnake(d, f));
			} else {
				ret[key] = k[1];
			}
			return null;
		});
		return ret;
	}
	if (obj !== null && obj !== undefined && obj.constructor === Array) {
		return obj.map((d: any) => convertObjectCamelSnake(d, f));
	}
	return obj;
};

export const DocumentTypeLookup: any = {
	bill_of_lading: 'Bill Of Lading',
	certificate_of_origin: 'Certificate Of Origin',
};

export const DocumentsKeysLookup: any = {
	// bill of lading
	shipper: 'Shipper',
	cosignee: 'Cosignee',
	notifyPartyAddress: 'Notify Party Address',
	vesselAndVoyageNumber: 'Vessel And Voyage Number',
	portOfLoading: 'Port Of Loading',
	portOfDischarge: 'Port Of Discharge',
	totalPackages: 'Total Packages',
	dateOfIssue: 'Date Of Issue',
	HTS: 'HTS',
	loadingDate: 'Loading Date',
	grossWeight: 'Gross Weight',
	measurement: 'Measurement',
	invoiceDate: 'Invoice Date',
	invoiceNumber: 'Invoice Number',
	shippingCompanyName: 'Shipping Company Name',
	shippingCompanyAddress: 'Shipping Company Address',
	billNumber: 'Bill Number',
	placeOfIssue: 'Place Of Issue',
	movement: 'Movement',
	taxId: 'Tax Id',
	email: 'Email',
	scacCodeWayBillNumber: 'SCAC Code / Way Bill Number',
	// certificate of origin
};

export const USStatesList: string[] = [
	'Alabama',
	'Alaska',
	'American Samoa',
	'Arizona',
	'Arkansas',
	'California',
	'Colorado',
	'Connecticut',
	'Delaware',
	'District of Columbia',
	'Federated States of Micronesia',
	'Florida',
	'Georgia',
	'Guam',
	'Hawaii',
	'Idaho',
	'Illinois',
	'Indiana',
	'Iowa',
	'Kansas',
	'Kentucky',
	'Louisiana',
	'Maine',
	'Marshall Islands',
	'Maryland',
	'Massachusetts',
	'Michigan',
	'Minnesota',
	'Mississippi',
	'Missouri',
	'Montana',
	'Nebraska',
	'Nevada',
	'New Hampshire',
	'New Jersey',
	'New Mexico',
	'New York',
	'North Carolina',
	'North Dakota',
	'Northern Mariana Islands',
	'Ohio',
	'Oklahoma',
	'Oregon',
	'Palau',
	'Pennsylvania',
	'Puerto Rico',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Tennessee',
	'Texas',
	'Utah',
	'Vermont',
	'Virgin Island',
	'Virginia',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming',
].sort();

export const countriesList: string[] = ['United States of America'];

export const countriesList2: string[] = [
	'United States of America', // Added up here -- won't match with iso list below.
	'Afghanistan',
	'Albania',
	'Algeria',
	'American Samoa',
	'Andorra',
	'Angola',
	'Anguilla',
	'Antarctica',
	'Antigua and Barbuda',
	'Argentina',
	'Armenia',
	'Aruba',
	'Australia',
	'Austria',
	'Azerbaijan',
	'Bahamas',
	'Bahrain',
	'Bangladesh',
	'Barbados',
	'Belarus',
	'Belgium',
	'Belize',
	'Benin',
	'Bermuda',
	'Bhutan',
	'Bolivia (Plurinational State of)',
	'Bonaire, Sint Eustatius and Saba',
	'Bosnia and Herzegovina',
	'Botswana',
	'Bouvet Island',
	'Brazil',
	'British Indian Ocean Territory',
	'Brunei Darussalam',
	'Bulgaria',
	'Burkina Faso',
	'Burundi',
	'Cabo Verde',
	'Cambodia',
	'Cameroon',
	'Canada',
	'Cayman Islands',
	'Central African Republic',
	'Chad',
	'Chile',
	'China',
	'Christmas Island',
	'Cocos (Keeling) Islands',
	'Colombia',
	'Comoros',
	'Congo (the Democratic Republic of the)',
	'Congo',
	'Cook Islands',
	'Costa Rica',
	'Croatia',
	'Cuba',
	'Curaçao',
	'Cyprus',
	'Czechia',
	"Côte d'Ivoire",
	'Denmark',
	'Djibouti',
	'Dominica',
	'Dominican Republic',
	'Ecuador',
	'Egypt',
	'El Salvador',
	'Equatorial Guinea',
	'Eritrea',
	'Estonia',
	'Eswatini',
	'Ethiopia',
	'Falkland Islands [Malvinas]',
	'Faroe Islands',
	'Fiji',
	'Finland',
	'France',
	'French Guiana',
	'French Polynesia',
	'French Southern Territories',
	'Gabon',
	'Gambia',
	'Georgia',
	'Germany',
	'Ghana',
	'Gibraltar',
	'Greece',
	'Greenland',
	'Grenada',
	'Guadeloupe',
	'Guam',
	'Guatemala',
	'Guernsey',
	'Guinea',
	'Guinea-Bissau',
	'Guyana',
	'Haiti',
	'Heard Island and McDonald Islands',
	'Holy See',
	'Honduras',
	'Hong Kong',
	'Hungary',
	'Iceland',
	'India',
	'Indonesia',
	'Iran (Islamic Republic of)',
	'Iraq',
	'Ireland',
	'Isle of Man',
	'Israel',
	'Italy',
	'Jamaica',
	'Japan',
	'Jersey',
	'Jordan',
	'Kazakhstan',
	'Kenya',
	'Kiribati',
	"Korea (the Democratic People's Republic of)",
	'Korea (the Republic of)',
	'Kuwait',
	'Kyrgyzstan',
	"Lao People's Democratic Republic",
	'Latvia',
	'Lebanon',
	'Lesotho',
	'Liberia',
	'Libya',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'Macao',
	'Madagascar',
	'Malawi',
	'Malaysia',
	'Maldives',
	'Mali',
	'Malta',
	'Marshall Islands',
	'Martinique',
	'Mauritania',
	'Mauritius',
	'Mayotte',
	'Mexico',
	'Micronesia (Federated States of)',
	'Moldova (the Republic of)',
	'Monaco',
	'Mongolia',
	'Montenegro',
	'Montserrat',
	'Morocco',
	'Mozambique',
	'Myanmar',
	'Namibia',
	'Nauru',
	'Nepal',
	'Netherlands',
	'New Caledonia',
	'New Zealand',
	'Nicaragua',
	'Niger',
	'Nigeria',
	'Niue',
	'Norfolk Island',
	'Northern Mariana Islands',
	'Norway',
	'Oman',
	'Pakistan',
	'Palau',
	'Palestine, State of',
	'Panama',
	'Papua New Guinea',
	'Paraguay',
	'Peru',
	'Philippines',
	'Pitcairn',
	'Poland',
	'Portugal',
	'Puerto Rico',
	'Qatar',
	'Republic of North Macedonia',
	'Romania',
	'Russian Federation',
	'Rwanda',
	'Réunion',
	'Saint Barthélemy',
	'Saint Helena, Ascension and Tristan da Cunha',
	'Saint Kitts and Nevis',
	'Saint Lucia',
	'Saint Martin (French part)',
	'Saint Pierre and Miquelon',
	'Saint Vincent and the Grenadines',
	'Samoa',
	'San Marino',
	'Sao Tome and Principe',
	'Saudi Arabia',
	'Senegal',
	'Serbia',
	'Seychelles',
	'Sierra Leone',
	'Singapore',
	'Sint Maarten (Dutch part)',
	'Slovakia',
	'Slovenia',
	'Solomon Islands',
	'Somalia',
	'South Africa',
	'South Georgia and the South Sandwich Islands',
	'South Sudan',
	'Spain',
	'Sri Lanka',
	'Sudan',
	'Suriname',
	'Svalbard and Jan Mayen',
	'Sweden',
	'Switzerland',
	'Syrian Arab Republic',
	'Taiwan',
	'Tajikistan',
	'Tanzania, United Republic of',
	'Thailand',
	'Timor-Leste',
	'Togo',
	'Tokelau',
	'Tonga',
	'Trinidad and Tobago',
	'Tunisia',
	'Turkey',
	'Turkmenistan',
	'Turks and Caicos Islands',
	'Tuvalu',
	'Uganda',
	'Ukraine',
	'United Arab Emirates',
	'United Kingdom of Great Britain and Northern Ireland',
	'United States Minor Outlying Islands',
	'Uruguay',
	'Uzbekistan',
	'Vanuatu',
	'Venezuela (Bolivarian Republic of)',
	'Viet Nam',
	'Virgin Islands (British)',
	'Virgin Islands (U.S.)',
	'Wallis and Futuna',
	'Western Sahara',
	'Yemen',
	'Zambia',
	'Zimbabwe',
	'Åland Islands',
];

export const specialChars = /[`!@#$%^&*()_+\-=[\]{};':'\\|,.<>/?~]/;

export const countryListISO: any = {
	Afghanistan: 'AF',
	'Åland Islands': 'AX',
	Albania: 'AL',
	Algeria: 'DZ',
	'American Samoa': 'AS',
	Andorra: 'AD',
	Angola: 'AO',
	Anguilla: 'AI',
	Antarctica: 'AQ',
	'Antigua and Barbuda': 'AG',
	Argentina: 'AR',
	Armenia: 'AM',
	Aruba: 'AW',
	Australia: 'AU',
	Austria: 'AT',
	Azerbaijan: 'AZ',
	Bahamas: 'BS',
	Bahrain: 'BH',
	Bangladesh: 'BD',
	Barbados: 'BB',
	Belarus: 'BY',
	Belgium: 'BE',
	Belize: 'BZ',
	Benin: 'BJ',
	Bermuda: 'BM',
	Bhutan: 'BT',
	'Bolivia (Plurinational State of)': 'BO',
	'Bonaire, Sint Eustatius and Saba': 'BQ',
	'Bosnia and Herzegovina': 'BA',
	Botswana: 'BW',
	'Bouvet Island': 'BV',
	Brazil: 'BR',
	'British Indian Ocean Territory': 'IO',
	'Brunei Darussalam': 'BN',
	Bulgaria: 'BG',
	'Burkina Faso': 'BF',
	Burundi: 'BI',
	'Cabo Verde': 'CV',
	Cambodia: 'KH',
	Cameroon: 'CM',
	Canada: 'CA',
	'Cayman Islands': 'KY',
	'Central African Republic': 'CF',
	Chad: 'TD',
	Chile: 'CL',
	China: 'CN',
	'Christmas Island': 'CX',
	'Cocos (Keeling) Islands': 'CC',
	Colombia: 'CO',
	Comoros: 'KM',
	Congo: 'CG',
	'Congo (the Democratic Republic of the)': 'CD',
	'Cook Islands': 'CK',
	'Costa Rica': 'CR',
	"Côte d'Ivoire": 'CI',
	Croatia: 'HR',
	Cuba: 'CU',
	Curaçao: 'CW',
	Cyprus: 'CY',
	Czechia: 'CZ',
	Denmark: 'DK',
	Djibouti: 'DJ',
	Dominica: 'DM',
	'Dominican Republic': 'DO',
	Ecuador: 'EC',
	Egypt: 'EG',
	'El Salvador': 'SV',
	'Equatorial Guinea': 'GQ',
	Eritrea: 'ER',
	Estonia: 'EE',
	Ethiopia: 'ET',
	'Falkland Islands [Malvinas]': 'FK',
	'Faroe Islands': 'FO',
	Fiji: 'FJ',
	Finland: 'FI',
	France: 'FR',
	'French Guiana': 'GF',
	'French Polynesia': 'PF',
	'French Southern Territories': 'TF',
	Gabon: 'GA',
	Gambia: 'GM',
	Georgia: 'GE',
	Germany: 'DE',
	Ghana: 'GH',
	Gibraltar: 'GI',
	Greece: 'GR',
	Greenland: 'GL',
	Grenada: 'GD',
	Guadeloupe: 'GP',
	Guam: 'GU',
	Guatemala: 'GT',
	Guernsey: 'GG',
	Guinea: 'GN',
	'Guinea-Bissau': 'GW',
	Guyana: 'GY',
	Haiti: 'HT',
	'Heard Island and McDonald Islands': 'HM',
	'Holy See': 'VA',
	Honduras: 'HN',
	'Hong Kong': 'HK',
	Hungary: 'HU',
	Iceland: 'IS',
	India: 'IN',
	Indonesia: 'ID',
	'Iran (Islamic Republic of)': 'IR',
	Iraq: 'IQ',
	Ireland: 'IE',
	'Isle of Man': 'IM',
	Israel: 'IL',
	Italy: 'IT',
	Jamaica: 'JM',
	Japan: 'JP',
	Jersey: 'JE',
	Jordan: 'JO',
	Kazakhstan: 'KZ',
	Kenya: 'KE',
	Kiribati: 'KI',
	"Korea (the Democratic People's Republic of)": 'KP',
	'Korea (the Republic of)': 'KR',
	Kuwait: 'KW',
	Kyrgyzstan: 'KG',
	"Lao People's Democratic Republic": 'LA',
	Latvia: 'LV',
	Lebanon: 'LB',
	Lesotho: 'LS',
	Liberia: 'LR',
	Libya: 'LY',
	Liechtenstein: 'LI',
	Lithuania: 'LT',
	Luxembourg: 'LU',
	Macao: 'MO',
	'North Macedonia': 'MK',
	Madagascar: 'MG',
	Malawi: 'MW',
	Malaysia: 'MY',
	Maldives: 'MV',
	Mali: 'ML',
	Malta: 'MT',
	'Marshall Islands': 'MH',
	Martinique: 'MQ',
	Mauritania: 'MR',
	Mauritius: 'MU',
	Mayotte: 'YT',
	Mexico: 'MX',
	'Micronesia (Federated States of)': 'FM',
	'Moldova (the Republic of)': 'MD',
	Monaco: 'MC',
	Mongolia: 'MN',
	Montenegro: 'ME',
	Montserrat: 'MS',
	Morocco: 'MA',
	Mozambique: 'MZ',
	Myanmar: 'MM',
	Namibia: 'NA',
	Nauru: 'NR',
	Nepal: 'NP',
	Netherlands: 'NL',
	'New Caledonia': 'NC',
	'New Zealand': 'NZ',
	Nicaragua: 'NI',
	Niger: 'NE',
	Nigeria: 'NG',
	Niue: 'NU',
	'Norfolk Island': 'NF',
	'Northern Mariana Islands': 'MP',
	Norway: 'NO',
	Oman: 'OM',
	Pakistan: 'PK',
	Palau: 'PW',
	'Palestine, State of': 'PS',
	Panama: 'PA',
	'Papua New Guinea': 'PG',
	Paraguay: 'PY',
	Peru: 'PE',
	Philippines: 'PH',
	Pitcairn: 'PN',
	Poland: 'PL',
	Portugal: 'PT',
	'Puerto Rico': 'PR',
	Qatar: 'QA',
	Réunion: 'RE',
	Romania: 'RO',
	'Russian Federation': 'RU',
	Rwanda: 'RW',
	'Saint Barthélemy': 'BL',
	'Saint Helena, Ascension and Tristan da Cunha': 'SH',
	'Saint Kitts and Nevis': 'KN',
	'Saint Lucia': 'LC',
	'Saint Martin (French part)': 'MF',
	'Saint Pierre and Miquelon': 'PM',
	'Saint Vincent and the Grenadines': 'VC',
	Samoa: 'WS',
	'San Marino': 'SM',
	'Sao Tome and Principe': 'ST',
	'Saudi Arabia': 'SA',
	Senegal: 'SN',
	Serbia: 'RS',
	Seychelles: 'SC',
	'Sierra Leone': 'SL',
	Singapore: 'SG',
	'Sint Maarten (Dutch part)': 'SX',
	Slovakia: 'SK',
	Slovenia: 'SI',
	'Solomon Islands': 'SB',
	Somalia: 'SO',
	'South Africa': 'ZA',
	'South Georgia and the South Sandwich Islands': 'GS',
	'South Sudan': 'SS',
	Spain: 'ES',
	'Sri Lanka': 'LK',
	Sudan: 'SD',
	Suriname: 'SR',
	'Svalbard and Jan Mayen': 'SJ',
	Eswatini: 'SZ',
	Sweden: 'SE',
	Switzerland: 'CH',
	'Syrian Arab Republic': 'SY',
	'Taiwan, Province of China[a]': 'TW',
	Tajikistan: 'TJ',
	'Tanzania, United Republic of': 'TZ',
	Thailand: 'TH',
	'Timor-Leste': 'TL',
	Togo: 'TG',
	Tokelau: 'TK',
	Tonga: 'TO',
	'Trinidad and Tobago': 'TT',
	Tunisia: 'TN',
	Turkey: 'TR',
	Turkmenistan: 'TM',
	'Turks and Caicos Islands': 'TC',
	Tuvalu: 'TV',
	Uganda: 'UG',
	Ukraine: 'UA',
	'United Arab Emirates': 'AE',
	'United Kingdom of Great Britain and Northern Ireland': 'GB',
	'United States of America': 'US',
	'United States Minor Outlying Islands': 'UM',
	Uruguay: 'UY',
	Uzbekistan: 'UZ',
	Vanuatu: 'VU',
	'Venezuela (Bolivarian Republic of)': 'VE',
	'Viet Nam': 'VN',
	'Virgin Islands (British)': 'VG',
	'Virgin Islands (U.S.)': 'VI',
	'Wallis and Futuna': 'WF',
	'Western Sahara': 'EH',
	Yemen: 'YE',
	Zambia: 'ZM',
	Zimbabwe: 'ZW',
};

export const abbrevToState: any = {
	AL: 'Alabama',
	AK: 'Alaska',
	AS: 'American Samoa',
	AZ: 'Arizona',
	AR: 'Arkansas',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DE: 'Delaware',
	DC: 'District of Columbia',
	FL: 'Florida',
	GA: 'Georgia',
	GU: 'Guam',
	HI: 'Hawaii',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PA: 'Pennsylvania',
	PR: 'Puerto Rico',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UT: 'Utah',
	VT: 'Vermont',
	VI: 'Virgin Islands',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
};

export const stateToAbbrev: any = {
	Alabama: 'AL',
	Alaska: 'AK',
	'American Samoa': 'AS',
	Arizona: 'AZ',
	Arkansas: 'AR',
	California: 'CA',
	Colorado: 'CO',
	Connecticut: 'CT',
	Delaware: 'DE',
	'District of Columbia': 'DC',
	Florida: 'FL',
	Georgia: 'GA',
	Guam: 'GU',
	Hawaii: 'HI',
	Idaho: 'ID',
	Illinois: 'IL',
	Indiana: 'IN',
	Iowa: 'IA',
	Kansas: 'KS',
	Kentucky: 'KY',
	Louisiana: 'LA',
	Maine: 'ME',
	Maryland: 'MD',
	Massachusetts: 'MA',
	Michigan: 'MI',
	Minnesota: 'MN',
	Mississippi: 'MS',
	Missouri: 'MO',
	Montana: 'MT',
	Nebraska: 'NE',
	Nevada: 'NV',
	'New Hampshire': 'NH',
	'New Jersey': 'NJ',
	'New Mexico': 'NM',
	'New York': 'NY',
	'North Carolina': 'NC',
	'North Dakota': 'ND',
	Ohio: 'OH',
	Oklahoma: 'OK',
	Oregon: 'OR',
	Pennsylvania: 'PA',
	'Puerto Rico': 'PR',
	'Rhode Island': 'RI',
	'South Carolina': 'SC',
	'South Dakota': 'SD',
	Tennessee: 'TN',
	Texas: 'TX',
	Utah: 'UT',
	Vermont: 'VT',
	'Virgin Islands': 'VI',
	Virginia: 'VA',
	Washington: 'WA',
	'West Virginia': 'WV',
	Wisconsin: 'WI',
	Wyoming: 'WY',
};

export const NUMBERS_REG = /^[0-9.,]+$/;

export const COMMA_REG = /\B(?=(\d{3})+(?!\d))/g;

export const isValidUrl = (urlString: string) => {
	const urlPattern = new RegExp(
		'^(https?:\\/\\/)?' + // Validate protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // Validate domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // Validate OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Validate port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // Validate query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // Validate fragment locator

	return Boolean(urlPattern.test(urlString));
};

export function isValidEmail(email: string) {
	// eslint-disable-next-line no-useless-escape
	if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
		return true;
	}

	return false;
}

export const isAllNumbers = (txt: string): boolean => {
	const reg = /^\d+$/;

	if (reg.test(txt)) {
		return true;
	}

	return false;
};

export const isValidCompanyName = (inputtext: string): boolean => {
	const reg = /^[a-zA-Z1-9. ]+$/;

	return reg.test(inputtext);
};

export const isValidName = (inputtxt: string): boolean => {
	const reg = /^[a-zA-Z]+$/;

	if (reg.test(inputtxt)) {
		return true;
	}

	return false;
};

export const allLetter = (inputtxt: string): boolean => {
	const letters = /^[A-Za-z]+$/;

	if (inputtxt.match(letters) != null) {
		return true;
	}

	return false;
};

export const onlyLettersAndSpaces = (inputtxt: string): boolean => {
	if (/^[A-Za-z\s]*$/.test(inputtxt)) {
		return true;
	}

	return false;
};

export function querystring(nameArg: string, url = window.location.href): string | null {
	const name = nameArg.replace(/[[]]/g, '\\$&');

	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`, 'i');
	const results = regex.exec(url);

	if (results == null) {
		return null;
	}
	if (results[2] !== null) {
		return '';
	}
	// const temp = decodeURIComponent(results[2].replace(/\+/g, ' '));

	// return temp;
	return ''; // TODO:
}

export const locationSearchOptions = { componentRestrictions: { country: ['us'] } };

export const convertDatetimeEpoch = (datetimeString: string, convertLocal: boolean = true): number => {
	const timeEpoch = new Date(datetimeString).getTime(); // timestamp always
	if (!convertLocal) return timeEpoch;
	const offset = new Date().getTimezoneOffset() * SECONDSINMINUTE * MILLSECONDSINSECOND;
	return timeEpoch - offset;
};

export const formatDatetime = (dateString: string) => {
	// TODO: GET RID OF THIS
	const dateStringArr = dateString.split(' ')[0].split('-');

	return `${dateStringArr[1]}/${dateStringArr[2]}/${dateStringArr[0]}`;
};

export const formatDateToLocalTime = (s3Date: string): string => {
	// created to format date coming back from S3
	const dateObj = new Date(s3Date);

	// Extracting date components
	const year = dateObj.getFullYear();
	const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
	const day = String(dateObj.getDate()).padStart(2, '0');
	const hours = String(dateObj.getHours()).padStart(2, '0');
	const minutes = String(dateObj.getMinutes()).padStart(2, '0');

	return `${year}-${month}-${day} ${hours}:${minutes}`;
};
