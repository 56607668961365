/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { iamInterface } from 'src/Libs/types';

export interface developerApiKeyInterface {
	datetimeCreated: string;
	apiKey: string;
	apiKeyName: string;
	internalApiKeyId: string;
}

export interface UserDocumentsInterface {
	missingPerson: string[];
	uploadedPerson: string[];
}

export interface AdditionalUserDocumentsInterface extends UserDocumentsInterface {
	email: string;
}

export interface CompanyDocumentsInterface {
	missingCompany: string[];
	uploadedCompany: string[];
}

export interface userState {
	userId: string;
	email: string;
	// string bool
	emailVerified: string;
	firstName: string;
	lastName: string;
	role: string;
	personRelationship: string;
	personPhoneNumber: string;
	// string bool
	isAuthenticated: boolean;
	// for something else
	userView: string;
	addedBy: string | null;

	developerApiKeys: developerApiKeyInterface[];
	openRevokeModal: string;

	// documents
	userDocuments: UserDocumentsInterface | null;
	companyDocuments: CompanyDocumentsInterface | null;
	additionalUserDocuments: AdditionalUserDocumentsInterface[];

	iam: iamInterface | null;
}

const initialState: userState = {
	userId: '',
	email: '',
	emailVerified: 'false',
	firstName: '',
	lastName: '',
	role: '',
	personRelationship: '',
	personPhoneNumber: '',
	isAuthenticated: false,
	userView: 'accountOverview',
	addedBy: null,

	developerApiKeys: [],
	openRevokeModal: 'false',

	userDocuments: null,
	companyDocuments: null,
	additionalUserDocuments: [],

	iam: null,
};

export const userSlice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
		setIam: (state, action) => {
			state.iam = action.payload.iam;
		},

		setUserDocuments: (state, action) => {
			state.userDocuments = action.payload?.userDocuments;
			state.companyDocuments = action.payload?.companyDocuments;
			state.additionalUserDocuments = action.payload?.additionalUserDocuments;
		},
		setUserEmail: (state, action) => {
			state.email = action.payload?.email;
			if (action.payload?.emailVerified !== undefined && action.payload?.emailVerified !== null) {
				state.emailVerified = action.payload?.emailVerified;
			}
		},
		setEmailName: (state, action) => {
			state.email = action.payload?.email;
			state.firstName = action.payload?.firstName;
			state.lastName = action.payload?.lastName;
		},
		setUser: (state, action) => {
			state.userId = action.payload?.userId;
			state.email = action.payload?.email;
			state.firstName = action.payload?.firstName;
			state.lastName = action.payload?.lastName;

			state.addedBy = action.payload?.addedBy;
			state.emailVerified = action.payload?.emailVerified;
			state.role = action.payload?.role;
			state.personRelationship = action.payload?.personRelationship;
			state.personPhoneNumber = action.payload?.personPhoneNumber;
		},
		logout: (state) => {
			state.userId = '';
			state.email = '';
			state.emailVerified = 'false';
			state.firstName = '';
			state.lastName = '';
			state.role = '';
			state.personRelationship = '';
			state.personPhoneNumber = '';
			state.isAuthenticated = false;
			state.userView = 'accountOverview';
			state.addedBy = null;
			state.developerApiKeys = [];
			state.openRevokeModal = 'false';
			state.userDocuments = null;
			state.companyDocuments = null;
			state.additionalUserDocuments = [];
			state.iam = null;
		},
		setOpenRevokeModal: (state, action) => {
			state.openRevokeModal = action.payload?.openRevokeModal;
		},
		setUserId: (state, action) => {
			state.userId = action.payload?.userId;
		},
		setUserFirstName: (state, action) => {
			state.firstName = action.payload?.firstName;
		},
		setUserLastName: (state, action) => {
			state.lastName = action.payload?.lastName;
		},
		setIsAuthenticated: (state, action) => {
			// TODO
			state.isAuthenticated = action.payload?.isAuthenticated;
		},
		setDeveloperApiKeys: (state, action) => {
			state.developerApiKeys = action.payload?.developerApiKeys;
		},
	},
});

export const {
	setUser,
	setUserEmail,
	setEmailName,
	logout,
	setUserId,
	setUserFirstName,
	setUserLastName,
	setIsAuthenticated,
	setDeveloperApiKeys,
	setOpenRevokeModal,
	setUserDocuments,
	setIam,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
