/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import {
	APILogsInterface,
	APIRequestMetricInterface,
	iamInterface,
	netPaymentsMetricInterface,
	NewCustomersMetricInterface,
	SpendPerCustomerMetricInterface,
	SuccessfulPaymentMetricInterface,
	webhookInterface,
	webhookLogInterface,
	WebhookRequestMetricInterface,
} from 'src/Libs/types';

export interface companyPeopleInterface {
	// email, firstName, lastName, personRelationship, userRole
	email: string;
	firstName: string;
	lastName: string;
	// company title
	personRelationship: string;
	userRole: 'Member' | 'Owner';
	userId: string;
	iam: iamInterface;
}

export interface companyState {
	companyWebsite: string;
	companyName: string;
	companyLegalName: string;
	companyAddress1: string;
	companyAddress2: string;
	companyUsState: string;
	companyCountry: string;
	companyCity: string;
	companyZipcode: string;
	companyId: string;

	//  settings
	companyPeople: companyPeopleInterface[];
	enabled: string | null;
	webhooks: webhookInterface[];
	webhookLogs: webhookLogInterface[];
	webhookLogsHasMore: boolean;
	webhookLogsResourceCount: number;
	webhookLogsPageNum: number;
	apiLogs: APILogsInterface[];
	apiLogsHasMore: boolean;
	apiLogsResourceCount: number;
	apiLogsPageNum: number;

	// metrics
	// developer metrics
	apiRequestMetric: APIRequestMetricInterface[];
	webhookRequestMetric: WebhookRequestMetricInterface[];
	// home metrics
	netPaymentsMetric: netPaymentsMetricInterface[];
	successfulPaymentsMetric: SuccessfulPaymentMetricInterface[];
	newCustomersMetric: NewCustomersMetricInterface[];
	spendPerCustomerMetric: SpendPerCustomerMetricInterface[];
}

const initialState: companyState = {
	companyWebsite: '',
	companyName: '',
	companyLegalName: '',
	companyAddress1: '',
	companyAddress2: '',
	companyUsState: '',
	companyCountry: '',
	companyCity: '',
	companyZipcode: '',
	companyId: '',

	//  settings
	companyPeople: [],
	enabled: null,
	webhooks: [],
	webhookLogs: [],
	webhookLogsHasMore: false,
	webhookLogsResourceCount: 0,
	webhookLogsPageNum: 0,
	apiLogs: [],
	apiLogsHasMore: false,
	apiLogsResourceCount: 0,
	apiLogsPageNum: 0,

	// metrics
	// developer metrics
	apiRequestMetric: [],
	webhookRequestMetric: [],
	// home metrics
	netPaymentsMetric: [],
	successfulPaymentsMetric: [],
	newCustomersMetric: [],
	spendPerCustomerMetric: [],
};

export const companySlice = createSlice({
	name: 'company',
	initialState: initialState,
	reducers: {
		setSpendPerCustomerMetric: (state, action) => {
			state.spendPerCustomerMetric = action.payload?.spendPerCustomerMetric;
		},
		setNewCustomersMetric: (state, action) => {
			state.newCustomersMetric = action.payload?.newCustomersMetric;
		},
		setSuccessfulPaymentsMetric: (state, action) => {
			state.successfulPaymentsMetric = action.payload?.successfulPaymentsMetric;
		},
		setNetPaymentsMetric: (state, action) => {
			state.netPaymentsMetric = action.payload?.netPaymentsMetric;
		},
		setApiRequestMetrics: (state, action) => {
			state.apiRequestMetric = action.payload?.apiRequestMetric;
		},
		setWebhookRequestMetrics: (state, action) => {
			state.webhookRequestMetric = action.payload?.webhookRequestMetric;
		},
		setApiLogs: (state, action) => {
			state.apiLogs = action.payload?.apiLogs;
			state.apiLogsHasMore = action.payload?.apiLogsHasMore;
			state.apiLogsResourceCount = action.payload?.apiLogsResourceCount;
			state.apiLogsPageNum = action.payload?.apiLogsPageNum;
		},
		appendApiLogs: (state, action) => {
			if (state.apiLogs === null || state.apiLogs.length === 0) {
				return {
					...state,
					apiLogs: action.payload?.apiLogs,
					apiLogsHasMore: action.payload?.apiLogsHasMore,
					apiLogsResourceCount: action.payload?.apiLogsResourceCount,
					apiLogsPageNum: action.payload?.apiLogsPageNum,
				};
			}
			return {
				...state,
				apiLogs: [...state.apiLogs, ...action.payload.apiLogs],
				apiLogsHasMore: action.payload?.apiLogsHasMore,
				apiLogsResourceCount: action.payload?.apiLogsResourceCount,
				apiLogsPageNum: action.payload?.apiLogsPageNum,
			};
		},
		setWebhookLogs: (state, action) => {
			state.webhookLogs = action.payload?.webhookLogs;
		},
		appendWebhookLogs: (state, action) => {
			if (state.apiLogs === null || state.apiLogs.length === 0) {
				return {
					...state,
					webhookLogs: action.payload?.webhookLogs,
					webhookLogsHasMore: action.payload?.webhookLogsHasMore,
					webhookLogsResourceCount: action.payload?.webhookLogsResourceCount,
					webhookLogsPageNum: action.payload?.webhookLogsPageNum,
				};
			}
			return {
				...state,
				webhookLogs: [...state.webhookLogs, ...action.payload.webhookLogs],
				webhookLogsHasMore: action.payload?.webhookLogsHasMore,
				webhookLogsResourceCount: action.payload?.webhookLogsResourceCount,
				webhookLogsPageNum: action.payload?.webhookLogsPageNum,
			};
		},
		setWebhooks: (state, action) => {
			state.webhooks = action.payload?.webhooks;
		},

		setCompany: (state, action) => {
			state.companyWebsite = action.payload?.companyWebsite;
			state.companyName = action.payload?.companyName;
			state.companyAddress1 = action.payload?.companyAddress1;
			state.companyAddress2 = action.payload?.companyAddress2;
			state.companyUsState = action.payload?.companyUsState;
			state.companyCountry = action.payload?.companyCountry;
			state.companyCity = action.payload?.companyCity;
			state.companyZipcode = action.payload?.companyZipcode;
			state.companyId = action.payload?.companyId;
			if (action.payload?.companyLegalName !== null && action.payload?.companyLegalName !== undefined) {
				state.companyLegalName = action.payload?.companyLegalName;
			}
		},
		setCompanyId: (state, action) => {
			state.companyId = action.payload?.companyId;
		},
		setCompanyPeople: (state, action) => {
			state.companyPeople = action.payload?.companyPeople;
		},
		setCompanyName: (state, action) => {
			state.companyName = action.payload?.companyName;
		},

		logoutCompany: (state) => {
			state.companyWebsite = '';
			state.companyName = '';
			state.companyAddress1 = '';
			state.companyAddress2 = '';
			state.companyUsState = '';
			state.companyCountry = '';
			state.companyCity = '';
			state.companyZipcode = '';
			state.companyId = '';
			state.companyPeople = [];

			//  settings
			state.companyPeople = [];
			state.webhooks = [];
			state.webhookLogs = [];
			state.webhookLogsHasMore = false;
			state.webhookLogsResourceCount = 0;
			state.webhookLogsPageNum = 0;
			state.apiLogs = [];
			state.apiLogsHasMore = false;
			state.apiLogsResourceCount = 0;
			state.apiLogsPageNum = 0;

			// metrics
			// developer metrics
			state.apiRequestMetric = [];
			state.webhookRequestMetric = [];
			// home metrics
			state.netPaymentsMetric = [];
			state.successfulPaymentsMetric = [];
			state.newCustomersMetric = [];
			state.spendPerCustomerMetric = [];
		},
	},
});

export const {
	setCompany,
	logoutCompany,
	setCompanyId,

	setCompanyPeople,
	setCompanyName,
	setWebhooks,
	setWebhookLogs,
	appendWebhookLogs,
	setApiLogs,
	appendApiLogs,
	// metrics
	setApiRequestMetrics,
	setWebhookRequestMetrics,
	setSuccessfulPaymentsMetric,
	setNewCustomersMetric,
	setNetPaymentsMetric,
	setSpendPerCustomerMetric,
} = companySlice.actions;

export const selectCompany = (state: RootState) => state.company;

export default companySlice.reducer;
