import { Alert } from '@material-ui/lab';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { allLetter, formatCognitoError, getAPI, isValidEmail, querystring, specialChars } from 'src/Libs/helper';
import { MAXPASSLEN, MINARRAYLEN, MINPASSLEN } from 'src/Libs/magicConst';
import { BasicSignUpUserInterface } from 'src/Libs/types';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import logo from 'src/assets/logo/logo_black.svg';
import RingsIcon from 'src/assets/rings.svg';
import { selectCompany } from 'src/slices/companySlice';
import { selectUser, setUserId } from 'src/slices/userSlice';

import styles from './SignupStartApp.module.css';

export const SignupStartApp: React.FC = () => {
	// imports
	const user = useAppSelector(selectUser);
	const company = useAppSelector(selectCompany);
	const history = useHistory();
	const [ableSignup, setAbleSignup] = useState<boolean>(true);

	useEffect(() => {
		if (user.isAuthenticated && company.companyId !== '' && user.userId !== '') {
			// TODO: add alert that user is logged in
			const redirect = querystring('redirect');

			history.push(redirect === '/' || redirect === null ? '/' : redirect);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const dispatch = useAppDispatch();
	// alerting
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [s1, setS1] = useState<boolean>(true);

	// patriot act
	const patriotActRef = useRef<HTMLDivElement>(null);

	const [rootUser, setRootUser] = useState<BasicSignUpUserInterface>({
		email: '',
		password: '',
		confirmPassword: '',
		confirmationCode: '',
		firstName: '',
		lastName: '',
	});

	// // invited
	const [isInvitedUser, setIsInvitedUser] = useState<boolean>(false);

	useEffect(() => {
		if (window.location.href.includes('invite')) {
			axios
				.get(
					`${getAPI('auth', 'signupSession')}?user_id=${
						window.location.href.split('userId=')[1]
					}&event_type=invited_start_app`
				)
				.then((response) => {
					setAbleSignup(true);
					setIsInvitedUser(true);
					setRootUser((prev) => ({
						...prev,
						email: response.data.email,
						firstName: response.data.firstName,
						lastName: response.data.lastName,
					}));
				})
				.catch(() => setAbleSignup(false));
		}
	}, []);

	const [confirmationCode, setConfirmationCode] = useState<string>('');
	const [codeResent, setCodeResent] = useState<boolean>(false);
	const [manualDisableVerify, setManualDisableVerify] = useState<boolean>(false);
	const [overrideDisable, setOverrideDisable] = useState<boolean>(false);

	const handleSubmitP1 = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSuccess(false);
		setIsError(false);
		setErrorMessage('');
		if (rootUser.firstName.length === MINARRAYLEN) {
			setIsError(true);
			setErrorMessage('Legal First Name is Required');
		} else if (!allLetter(rootUser.firstName)) {
			setIsError(true);
			setErrorMessage("Legal First Name can't contain invalid symbols");
		} else if (rootUser.lastName.trim().length === MINARRAYLEN) {
			setIsError(true);
			setErrorMessage('Legal Last Name is Required');
		} else if (!allLetter(rootUser.lastName)) {
			setIsError(true);
			setErrorMessage("Legal Last Name can't contain invalid symbols");
		} else if (rootUser.email.length === MINARRAYLEN) {
			setIsError(true);
			setErrorMessage('Email is Required');
		} else if (!isValidEmail(rootUser.email)) {
			setIsError(true);
			setErrorMessage('Invalid Email');
		} else if (rootUser.password.trim().length === MINARRAYLEN) {
			setIsError(true);
			setErrorMessage('Password is Required');
		} else if (rootUser.confirmPassword !== rootUser.password) {
			setIsError(true);
			setErrorMessage('Passwords Do Not Match.');
		}
		// else if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/.test(password))) {setIsError(true); setErrorMessage("Passwords Do Not Match.");}
		else if (rootUser.password.length < MINPASSLEN || rootUser.password.length > MAXPASSLEN) {
			setIsError(true);
			setErrorMessage('Password must contain at least 9 characters and fewer than 99 characters.');
		} else if (!/\d/.test(rootUser.password)) {
			setIsError(true);
			setErrorMessage('Password must contain at least one digit.');
		} else if (!specialChars.test(rootUser.password)) {
			setIsError(true);
			setErrorMessage('Password must contain at least one special character.');
		} else if (rootUser.password.toLowerCase() === rootUser.password) {
			setIsError(true);
			setErrorMessage('Password must contain at least one uppercase character.');
		} else if (rootUser.password.toUpperCase() === rootUser.password) {
			setIsError(true);
			setErrorMessage('Password must contain at least one lowercase character.');
		} else {
			setIsLoading(true);
			Auth.signUp({
				username: rootUser.email,
				password: rootUser.password,
				clientMetadata: {
					first_name: rootUser.firstName,
					last_name: rootUser.lastName,
				},
			})
				.then((response) => {
					dispatch(setUserId({ userId: response.userSub }));
					setIsLoading(false);
					setIsError(false);
					setErrorMessage('');
					setS1(false);
				})
				.catch((error) => {
					setIsLoading(false);
					setIsSuccess(false);
					setIsError(true);
					setErrorMessage(formatCognitoError(error.message));
				});
		}
	};

	const handleConfirmationSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		setCodeResent(false); // to clear for any error messages here
		event.preventDefault();
		if (confirmationCode.length === MINARRAYLEN) {
			setIsError(true);
			setErrorMessage('Please enter Confirmation code');
		} else {
			const confirmSignUpRequestBody: any = {
				clientMetadata: {
					first_name: rootUser.firstName,
					last_name: rootUser.lastName,
				},
			};

			if (isInvitedUser) {
				confirmSignUpRequestBody.clientMetadata.user_id_temp = window.location.href.split('userId=')[1];
			}
			setIsLoading(true);
			Auth.confirmSignUp(rootUser.email, confirmationCode, confirmSignUpRequestBody)
				.then(() => {
					setIsLoading(false);
					setIsError(false);
					setErrorMessage('');
					setCodeResent(false); // get rid of that message
					setIsSuccess(true); // success message automated
					setOverrideDisable(true);
					/*
					 * history.push('/signup/userId=' + user.userId)
					 * signUserIn();
					 * axios.post(getAPI("auth", "postSignupForm"), {
					 *     "userId" : user.userId,
					 *     "email" : rootUser.email,
					 *     "firstName" : rootUser.firstName,
					 *     "lastName" : rootUser.lastName
					 * })
					 * .then((response) => {
					 *     const userId = user.userId;
					 *     setIsLoading(false); setIsError(false); setErrorMessage("");
					 *     setCodeResent(false); // get rid of that message
					 *     history.push('/signup/userId=' + user.userId)
					 */

					/*
					 * })
					 * .catch((error) => {
					 *     setIsSuccess(false); setSuccessMessage("");
					 *     setIsError(true); setIsLoading(false); setErrorMessage(error.response?.data['message']);
					 *     if (error.response?.data['message'].includes('support')) {
					 *         setManualDisableVerify(true);
					 *     }
					 * });
					 */
				})

				.catch((error) => {
					if (error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
						setManualDisableVerify(true);
						setIsLoading(false);
						setIsError(false);
						setErrorMessage('');
						setIsSuccess(false);
					} else {
						setIsLoading(false);
						setIsError(true);
						setErrorMessage(error.message.replace('PostConfirmation failed with error', ''));
						setCodeResent(false); // get rid of that message
					}
				});
		}
	};
	const sendNewConfirmationCode = () => {
		setIsError(false);
		setErrorMessage('');
		Auth.resendSignUp(rootUser.email)
			.then(() => {
				setIsError(false);
				setErrorMessage('');
				setCodeResent(true);
			})
			.catch((error) => {
				setIsError(true);
				setErrorMessage(error.message);
			});
	};

	const renderPError = () => (
		<div className={styles.container}>
			<div className={styles.header__wrapper}>
				<img src={logo} className={styles.logo} alt="asset" />
				<h1 className={styles.logo__text}> neuraml </h1>
			</div>
			<div className={styles.unable__signup__wrapper}>
				<div className={styles.unable__signup}>Currently Unable to Sign Up. Please try again later</div>
			</div>
		</div>
	);

	const renderP1 = () => (
		<div className={styles.container}>
			<div className={styles.header__wrapper}>
				<img src={logo} className={styles.logo} alt="asset" />
				<h1 className={styles.logo__text}> neuraml </h1>
			</div>
			{isError && (
				<Alert severity="error" className={styles.alert__message}>
					{errorMessage}
				</Alert>
			)}
			<form onSubmit={handleSubmitP1} className={styles.form}>
				<div className={styles.form__header__wrapper}>
					<div className={styles.form__header__title}> Get Started</div>
					<div className={styles.form__header__text}>Welome to neuraml - Create an account</div>
				</div>
				<div className={styles.two__section__wrapper}>
					<div>
						<div className={styles.field__label}> Legal First Name </div>
						<input
							type="text"
							name="firstName"
							placeholder=""
							className={cx(styles.section__input, styles.half__section__input)}
							value={rootUser.firstName}
							onChange={(e) =>
								setRootUser({
									...rootUser,
									firstName: e.target.value,
								})
							}
							autoComplete="name"
							disabled={isInvitedUser}
						/>
					</div>
					<div>
						<div className={styles.field__label}> Legal Last Name </div>
						<input
							type="text"
							name="lastName"
							placeholder=""
							className={cx(styles.section__input, styles.half__section__input)}
							value={rootUser.lastName}
							onChange={(e) =>
								setRootUser({
									...rootUser,
									lastName: e.target.value,
								})
							}
							autoComplete="name"
							disabled={isInvitedUser}
						/>
					</div>
				</div>
				<div className={styles.field__label}> Work Email </div>
				<input
					type="email"
					name="email"
					placeholder=""
					className={cx(styles.section__input)}
					value={rootUser.email}
					onChange={(e) =>
						setRootUser({
							...rootUser,
							email: e.target.value,
						})
					}
					autoComplete="email"
					disabled={isInvitedUser}
				/>
				<div className={styles.field__label}> Password </div>
				<input
					type="password"
					name="password"
					placeholder=""
					className={cx(styles.section__input)}
					value={rootUser.password}
					onChange={(e) =>
						setRootUser({
							...rootUser,
							password: e.target.value,
						})
					}
					autoComplete="password"
				/>
				<div className={styles.field__label}> Confirm Password </div>
				<input
					type="password"
					placeholder=""
					value={rootUser.confirmPassword}
					className={cx(styles.section__input, styles.section__input__last)}
					onChange={(e) =>
						setRootUser({
							...rootUser,
							confirmPassword: e.target.value,
						})
					}
					autoComplete="new-password"
				/>

				<div
					className={cx(styles.div__text, styles.patriot__act)}
					ref={patriotActRef}
					onScroll={() => {
						if (patriotActRef.current != null) {
							const { scrollTop, scrollHeight, clientHeight } = patriotActRef.current;

							if (scrollTop + clientHeight === scrollHeight) {
								// setPatriotActScrollBottom(true);
							}
						}
					}}
				></div>
				<br />
				<div className={styles.div__text}>
					By clicking below you also agree to our&nbsp;
					<Link to="/terms-and-conditions"> Terms and Conditions</Link>
					&nbsp;and&nbsp;
					<Link to="/privacy">Privacy Policy</Link>
				</div>
				<br />
				<br />

				<div className={cx(styles.button__container)}>
					<button
						className={styles.form__button}
						type="submit"
						// disabled={!validateFormPageOne()}
					>
						{isLoading ? (
							<img src={RingsIcon} alt="click to start application" className={styles.rings} />
						) : (
							'Start Application'
						)}
					</button>
				</div>
				<div className={styles.links} />
				<div className={styles.login__links}>
					<Link id="center" to="/login" className={cx(styles.field__label, styles.login__link)}>
						Already have an account? Login
					</Link>
				</div>
			</form>
		</div>
	);

	const renderConfirmationForm = () => (
		<div className={styles.wrapper}>
			<div className={styles.header__wrapper}>
				<img src={logo} className={styles.logo} alt="asset" />
				<h1 className={styles.logo__text}> neuraml </h1>
			</div>
			{isError && (
				<Alert severity="error" className={styles.alert__message}>
					{errorMessage}
				</Alert>
			)}
			{isSuccess && (
				<Alert severity="success" className={styles.alert__message}>
					Your account has been successfully Verified! Please check your email for next steps.
				</Alert>
			)}
			{codeResent && (
				<Alert severity="info" className={styles.alert__message}>
					New Confirmation Code sent to
					{rootUser.email}.
				</Alert>
			)}
			<form onSubmit={handleConfirmationSubmit} className={styles.form}>
				<div className={styles.form__header__wrapper}>
					<div className={styles.form__header__title}> Confirmation Code </div>
					<div className={styles.form__header__text}>Please check your email for the code.</div>
				</div>
				<div className={styles.field__label}> Confirmation Code </div>
				<input
					type="text"
					value={confirmationCode}
					className={cx(styles.section__input)} // disabled={!haveCompanyWebsite}
					onChange={(e) => setConfirmationCode(e.target.value.trim())}
				/>

				<div className={cx(styles.button__container, { [styles.button__container2]: true })}>
					{!isSuccess && (
						<button
							type="button"
							className={cx(styles.form__button, {
								[styles.form__button__disabled]:
									overrideDisable ||
									isLoading ||
									(errorMessage !== 'Invalid verification code provided, please try again.' &&
										errorMessage !== 'Invalid code provided, please request a code again.'),
							})}
							disabled={
								overrideDisable ||
								isLoading ||
								(errorMessage !== 'Invalid verification code provided, please try again.' &&
									errorMessage !== 'Invalid code provided, please request a code again.')
							}
							onClick={() => {
								sendNewConfirmationCode();
							}}
						>
							Send New Code
						</button>
					)}

					{!isSuccess && (
						<button
							className={cx(styles.form__button, {
								[styles.form__button__disabled]: manualDisableVerify || overrideDisable,
							})}
							type="submit"
							disabled={manualDisableVerify || overrideDisable}
						>
							{isLoading ? <img src={RingsIcon} alt="loading" /> : 'Verify'}
						</button>
					)}
				</div>
			</form>
		</div>
	);

	return (
		<div>
			{!ableSignup && renderPError()}
			{ableSignup && s1 && renderP1()}
			{ableSignup && !s1 && renderConfirmationForm()}
		</div>
	);
};

export default SignupStartApp;
