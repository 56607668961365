import axios from 'axios';
import React, { useState } from 'react';
import { convertDatetimeEpoch, env, formatUser, getAPI } from 'src/Libs/helper';
import { MAX_APIKEY_NAME_LENGTH, MINARRAYLEN } from 'src/Libs/magicConst';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import CopySettingsIcon from 'src/assets/copySettings.svg';
import rings from 'src/assets/rings.svg';
import config from 'src/config';
import { selectCompany } from 'src/slices/companySlice';
import { developerApiKeyInterface, selectUser, setDeveloperApiKeys } from 'src/slices/userSlice';

import styles from './CreateApiKeyModal.module.css';

interface Props {
	createSuccess: (errMessage: string) => void;
	createError: (errMessage: string) => void;
	clearMessages: () => void;
}

export const CreateApiKeyModal: React.FC<Props> = ({ createSuccess, createError, clearMessages }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [apiKeyName, setApiKeyName] = useState<string>('');
	const dispatch = useAppDispatch();

	const user = useAppSelector(selectUser);
	const company = useAppSelector(selectCompany);
	const accessToken: string =
		localStorage[
			`CognitoIdentityServiceProvider.${config[env].cognito.APP_CLIENT_ID}.${formatUser(user.userId)}.accessToken`
		];

	const [newApiKeyCreated, setnewApiKeyCreated] = useState<developerApiKeyInterface>();

	const createAPIKey = (event: React.FormEvent) => {
		event.preventDefault();
		if (apiKeyName === '') {
			createError('Must Provide Api Key Name');
			return;
		}
		clearMessages();
		setIsLoading(true);
		axios
			.post(
				getAPI('auth', 'apiKeys'),
				{
					companyName: company.companyName,
					apiKeyName: apiKeyName,
					userEmail: user.email,
				},
				{ headers: { Authorization: `Bearer ${accessToken}` } }
			)
			.then((response) => {
				const newApiKey: developerApiKeyInterface = response.data;

				setnewApiKeyCreated(newApiKey);
				axios
					.get(getAPI('auth', 'apiKeys'), {
						headers: { Authorization: `Bearer ${accessToken}` },
					})
					.then((res) => {
						const apiKeys = res.data;

						if (typeof apiKeys !== 'object') {
							// typof [] === 'object
							/*
							 * internal error
							 * console.log('internal error with apiKeys', apiKeys, company.companyId);
							 */
						} else if (apiKeys.length === MINARRAYLEN) {
							dispatch(setDeveloperApiKeys({ developerApiKeys: [] }));
						} else {
							dispatch(setDeveloperApiKeys({ developerApiKeys: apiKeys }));
						}
					});
				createSuccess('New Api Key Created');
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
				createError('Api Key Name Must be unique in your Organization');
			});
	};
	return (
		<div>
			{newApiKeyCreated === undefined && (
				<form autoComplete="off" onSubmit={createAPIKey} className={styles.root}>
					<div className={styles.title}> Create New API Key </div>
					<div>
						<div className={styles.field__label}> Api Key Name </div>
						<input
							autoComplete="off"
							aria-autocomplete="none"
							type="text"
							name="Enter Api Key Name"
							placeholder=""
							className={styles.section__input}
							value={apiKeyName}
							onChange={(e) => {
								if (e.target.value.length < MAX_APIKEY_NAME_LENGTH) setApiKeyName(e.target.value);
							}}
						/>
					</div>

					<div className={styles.title}> Permissions </div>
					<div className={styles.setting__container}>
						<input
							type="radio"
							className={styles.role__option__checkbox}
							style={{ marginRight: '5px' }}
							defaultChecked
						/>
						<div> Full Access </div>
					</div>

					<button type="button" className={styles.create__button} onClick={(event) => createAPIKey(event)}>
						{isLoading ? <img src={rings} alt="loading" /> : 'Create Api Key'}
					</button>
				</form>
			)}
			{newApiKeyCreated !== undefined && (
				<div className={styles.root}>
					<div className={styles.title}> Please Copy this Api Key as it will only be displayed here.</div>
					<div className={styles.record}>
						<div className={styles.record__label}>Datetime Created: </div>
						<div>
							{new Date(convertDatetimeEpoch(newApiKeyCreated.datetimeCreated)).toLocaleString(
								navigator.language,
								{
									year: 'numeric',
									month: 'short',
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								}
							)}
						</div>
					</div>
					<div className={styles.record}>
						<div className={styles.record__label}>Api Key Name: </div>
						<div>{newApiKeyCreated.apiKeyName}</div>
					</div>
					<div className={styles.record}>
						<div className={styles.record__label}>Api Key: </div>
						<div>{newApiKeyCreated.apiKey}</div>
						<img
							className={styles.copy__image}
							src={CopySettingsIcon}
							onClick={() => {
								navigator.clipboard.writeText(newApiKeyCreated.apiKey);
								createSuccess('Api Key copied to clipboard');
							}}
							alt="asset"
						/>
					</div>

					<div className={styles.title}> Permissions </div>
					<div> Full Access </div>
				</div>
			)}
		</div>
	);
};

export default CreateApiKeyModal;
