/* global localStorage,sessionStorage  */
import React from 'react';
import NavbarPostLogin from 'src/features/Shared/NavbarPostLogin';
import Sidebar from 'src/features/Shared/Sidebar';

// import styles from 'src/features/Shared/CSS/scenetemplate.module.css';
import 'src/features/Shared/CSS/bodycss.css';

import styles from './ProfileSettings.module.css';

export const ProfileSettings: React.FC = () => (
	<div className="root">
		<NavbarPostLogin />

		<div className="root_body">
			<Sidebar />
			<div className="component__container">
				<div className="component_body">
					<div className={styles.container}>
						<div className={styles.container_header}>
							<div className={styles.container_header_body}>
								<div className={styles.header_title}>Public Information</div>
								<div className={styles.header_secondary}>
									Your support information may be visible in payment statements, invoices, and
									receipts for your customers.
								</div>
							</div>
						</div>
						<div className={styles.container_body}>
							<div className={styles.container_body_cell}>
								<div className={styles.cell_header}>
									<div className={styles.cell_name}>Business Name</div>
									{/* <img src={HelpIcon} alt="Help tooltip" /> */}
								</div>
								<input type="text" className={styles.company_name} />
							</div>

							<div className={styles.container_body_cell}>
								<div className={styles.cell_header}>
									<div className={styles.cell_name}>Support Email</div>
									{/* <img src={HelpIcon} alt="Help tooltip" /> */}
								</div>
								<input type="text" className={styles.company_name} />
							</div>

							<div className={styles.container_body_cell}>
								<div className={styles.cell_header}>
									<div className={styles.cell_name}>Support Phone Number</div>
									{/* <img src={HelpIcon} alt="Help tooltip" /> */}
								</div>
								<input type="text" className={styles.company_name} />
							</div>

							<div className={styles.container_body_cell}>
								<div className={styles.cell_header}>
									<div className={styles.cell_name}>Business Website</div>
									{/* <img src={HelpIcon} alt="Help tooltip" /> */}
								</div>
								<input type="text" className={styles.company_name} />
							</div>

							<div className={styles.container_body_cell}>
								<div className={styles.cell_header}>
									<div className={styles.cell_name}>Support Website</div>
									{/* <img src={HelpIcon} alt="Help tooltip" /> */}
								</div>
								<input type="text" className={styles.company_name} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default ProfileSettings;
