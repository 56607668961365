export const MODAL_OPACITY = 0.06;

export const MINVARLEN = 0;

export const MINARRAYLEN = 0;

export const MINAMOUNT = 0;

export const STARTIDX = 0;

export const FALLBACKVAL = 0;

export const TOPOFFILE = 0;

export const EINNUMBERLEN = 9;

export const MINPASSLEN = 9;

export const MAXPASSLEN = 98;

export const ARRAYOFFSET = 1;

export const DATEPAD = 2;

export const INITIALIZELOOP = 0;

export const INCREMENTVAR = 1;

export const PHONENUMBERLEN = 10;

export const ADDRESSROOTSPLIT = 4;

export const DESCRIPTIONMAXLEN = 50;

export const SSNLEN = 9;

export const ADDRESSSPLIT = 2;

export const USER_MFA_CODE_LEN = 6;

export const PERCENTNORMALIZE = 100.0;

export const SLICEBEGINNINGSTARTIDX = 0;

export const SLICEONECHARENDIDX = 1;

export const PRECISION = 2;

export const DAYSINWEEK = 7;

export const DAYSINMONTH = 30;

export const DAYSINTHREEMONTH = 90;

export const DAYSINSIXMONTH = 180;

export const DAYSINYEAR = 365;

export const HOURSINDAY = 24;

export const SECONDSINHOUR = 3600;

export const MILLSECONDSINSECOND = 1000;

export const SECONDSINMINUTE = 60;

export const RADIX = 10;

export const ADDRESSMIN = 10;

export const MAXUSDWITHDRAW = 1000000;

export const AVALANCHEADDRESSLENGTH = 42;

export const ROUTING_NUM_LEN = 9;

export const MIN_ACCOUNT_NUM_LEN = 5;

export const MAX_ACCOUNT_NUM_LEN = 17;

export const TEN_MILLION = 10_000_000;

export const MILLION = 1_000_000;

export const ORDERID_DISPLAY = 13;

export const MAX_PERCENT = 100;

export const APIKEYCHARS_DISPLAY = 3;

export const MAX_APIKEY_NAME_LENGTH = 30;

export const MAX_WEBHOOK_LENGTH = 80;

export const MAX_SHARED_SECRET_LENGTH = 72;

export const MIN_SHARED_SECRET_LENGTH = 8;
