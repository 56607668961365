/* global localStorage, sessionStorage */
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'src/app/hooks';
import { logoutCompany } from 'src/slices/companySlice';
import { logoutFlow } from 'src/slices/flowSlice';
import { logout } from 'src/slices/userSlice';

export const useSignOutUser = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const signOutUser = async () => {
		await Auth.signOut();
		localStorage.clear();
		sessionStorage.clear();
		dispatch(logout());
		dispatch(logoutFlow());
		dispatch(logoutCompany());
		history.push('/login');
	};

	return signOutUser;
};

export default useSignOutUser;
