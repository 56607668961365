/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { IssueInterface } from 'src/Libs/types';

import { companyPeopleInterface } from './companySlice';

export interface flowState {
	// issue state
	viewIssue: boolean;
	viewIssueId: string; // similar to viewTeamEmail
	issueToView: IssueInterface | undefined;
	issueFilter: string;
	issueTypeOption: string;
	issueStatusOption: 'All' | 'Completed' | 'Failed';
	issueStartDate: string;
	issueEndDate: string;

	// team state
	viewTeam: boolean;
	viewTeamEmail: string;
	personView: companyPeopleInterface | null;
	personFilter: string;
	personRoleFilter: 'All Roles' | 'Owner' | 'Member';

	// other
	treasuryTimeSeries: string;
	walletTimeSeries: string;
	walletCoinValue: 'Wallet Value' | 'Coin Price';
	commerceTimeSeries: string;

	// wallets view search bar
	walletsViewSearchBar: string;

	// dropdown
	dropDown: boolean;
	developerTemporalFilter: '4h' | '24h' | '1w';

	// navbar
	navbarSearch: string;
	sidebarFilters: string;
}

const initialState: flowState = {
	// issue state
	viewIssue: false,
	viewIssueId: '',
	issueToView: undefined,
	issueFilter: '',
	issueTypeOption: 'All',
	issueStatusOption: 'All',
	issueStartDate: '',
	issueEndDate: '',

	// wallet state
	viewTeam: false,
	viewTeamEmail: '',
	personView: null,
	personFilter: '',
	personRoleFilter: 'All Roles',

	treasuryTimeSeries: '1M',
	walletTimeSeries: '1M',
	walletCoinValue: 'Wallet Value',
	commerceTimeSeries: 'Current period',

	walletsViewSearchBar: '',

	// dropdown
	dropDown: false,

	developerTemporalFilter: '1w',

	// navbar
	navbarSearch: '',
	sidebarFilters: '',
};

export const flowSlice = createSlice({
	name: 'flow',
	initialState: initialState,
	reducers: {
		setSidebarFilters: (state, action) => {
			state.sidebarFilters = action.payload?.sidebarFilters;
		},
		setDeveloperTemporalFilter: (state, action) => {
			state.developerTemporalFilter = action.payload?.developerTemporalFilter;
		},
		logoutFlow: (state) => {
			// issue state
			state.viewIssue = false;
			state.viewIssueId = '';
			state.issueToView = undefined;
			state.issueFilter = '';
			state.issueTypeOption = 'All';
			state.issueStatusOption = 'All';
			state.issueStartDate = '';
			state.issueEndDate = '';

			// wallet state
			state.viewTeam = false;
			state.viewTeamEmail = '';
			state.personView = null;
			state.personFilter = '';
			state.personRoleFilter = 'All Roles';

			state.treasuryTimeSeries = '3m';
			state.walletTimeSeries = '7d';
			state.walletCoinValue = 'Wallet Value';
			state.commerceTimeSeries = 'Current period';

			state.walletsViewSearchBar = '';

			// dropdown
			state.dropDown = false;

			state.developerTemporalFilter = '1w';
		},
		// issues
		setViewIssue: (state, action) => {
			state.viewIssue = action.payload?.viewIssue;
		},
		setViewIssueId: (state, action) => {
			state.viewIssueId = action.payload?.viewIssueId;
		},
		setIssueToView: (state, action) => {
			state.issueToView = action.payload?.issueToView;
		},
		setIssueFilter: (state, action) => {
			state.issueFilter = action.payload?.issueFilter;
		},
		setIssueTypeOption: (state, action) => {
			state.issueTypeOption = action.payload?.issueTypeOption;
		},
		setIssueStatusOption: (state, action) => {
			state.issueStatusOption = action.payload?.issueStatusOption;
		},
		setIssueStartDate: (state, action) => {
			state.issueStartDate = action.payload?.issueStartDate;
		},
		setIssueEndDate: (state, action) => {
			state.issueEndDate = action.payload?.issueEndDate;
		},
		// team
		setViewTeam: (state, action) => {
			state.viewTeam = action.payload?.viewTeam;
		},
		setViewTeamEmail: (state, action) => {
			state.viewTeamEmail = action.payload?.viewTeamEmail;
		},
		setPersonView: (state, action) => {
			state.personView = action.payload?.personView;
		},
		setPersonFilter: (state, action) => {
			state.personFilter = action.payload?.personFilter;
		},
		setPersonRoleFilter: (state, action) => {
			state.personRoleFilter = action.payload?.personRoleFilter;
		},
		// other
		setTreasuryTimeSeries: (state, action) => {
			state.treasuryTimeSeries = action.payload?.treasuryTimeSeries;
		},
		setCommerceTimeSeries: (state, action) => {
			state.commerceTimeSeries = action.payload?.commerceTimeSeries;
		},
		setWalletTimeSeries: (state, action) => {
			state.walletTimeSeries = action.payload?.walletTimeSeries;
		},
		setWalletCoinValue: (state, action) => {
			state.walletCoinValue = action.payload?.walletCoinValue;
		},
		setWalletsViewSearchBar: (state, action) => {
			state.walletsViewSearchBar = action.payload?.walletsViewSearchBar;
		},
		// dropdown
		setDropDown: (state, action) => {
			state.dropDown = action.payload?.dropDown;
		},
	},
});

export const {
	// setFlow,logout,
	setSidebarFilters,
	setViewIssue,
	setViewTeam,
	setTreasuryTimeSeries,
	setWalletTimeSeries,
	setWalletCoinValue,
	setViewTeamEmail,
	setPersonView,
	setPersonFilter,
	setPersonRoleFilter,
	setViewIssueId,
	setIssueToView,
	setIssueFilter,
	setIssueEndDate,
	setIssueStartDate,
	setIssueStatusOption,
	setIssueTypeOption,
	setWalletsViewSearchBar,
	setDropDown,
	setCommerceTimeSeries,
	logoutFlow,
	setDeveloperTemporalFilter,
} = flowSlice.actions;

export const selectFlow = (state: RootState) => state.flow;

export default flowSlice.reducer;
