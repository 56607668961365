/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import { convertDatetimeEpoch } from 'src/Libs/helper';

import styles from './CustomRechartTooltip.module.css';

interface Props {
	active?: boolean;
	payload?: any;
	label?: any;
}

const valueLookup: any = {
	numCustomers: 'Number Customers',
	totalSuccess: 'Payments',
	spendPerCustomer: 'Total',
	netPayment: 'Net',
	// developer
	avgResponseTime: 'Response Time',
	totalRequests: 'Number Requests',
	totalFail: 'Number Fail',
};
// eslint-disable-next-line no-unused-vars
export const CustomRechartTooltip: React.FC<Props> = ({ active, payload, label }): any => {
	const formatDate = (date: string) =>
		new Date(convertDatetimeEpoch(date, false)).toLocaleDateString(navigator.language, {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		});

	if (active === true && payload !== null) {
		const payloadObj = payload[0];

		return (
			<div className={styles.root}>
				<div>{formatDate(payloadObj.payload.date)}</div>
				<div>
					{`${valueLookup[payloadObj.name]}: ${String(String(payload[0].value)).replace(
						/\B(?=(\d{3})+(?!\d))/g,
						','
					)}`}
				</div>
			</div>
		);
	}

	return null;
};

export default CustomRechartTooltip;

CustomRechartTooltip.defaultProps = { active: false, payload: undefined, label: undefined };
