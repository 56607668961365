import { Bookmark, BookmarkPlus, Download } from 'lucide-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDatetime } from 'src/Libs/helper';
import { ModelBasicInfo } from 'src/slices/modelSlice';
import styles from './ModelCard.module.css';

interface Props {
	model: ModelBasicInfo;
}

const ModelCard: React.FC<Props> = ({ model }) => {
	const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
	const history = useHistory();

	const handleBookmark = () => {
		setIsBookmarked(!isBookmarked);
		// Todo: do serverside implementation
	};

	return (
		<div
			className={styles.outerWrapper}
			onClick={() => {
				history.push(`/model/${model.modelId}`);
			}}
		>
			<div className={styles.innerWrapper}>
				<div className={styles.firstRow}>
					<div>
						<div className={styles.title}>neuraml/{model.modelName}</div>
						<div className={styles.description}>{model.modelDescription}</div>
					</div>
					<button className={styles.button_wrapper} onClick={handleBookmark}>
						<div className={styles.button_left}>
							{isBookmarked ? <Bookmark fill="white" /> : <BookmarkPlus />}
							{isBookmarked ? 'Saved' : 'Save'}
						</div>
					</button>
				</div>
				<div className={styles.tags}>
					{model.modelTags !== null &&
						model.modelTags !== undefined &&
						model.modelTags.length > 0 &&
						model.modelTags.map((tag) => (
							<span key={tag} className={styles.tag}>
								{tag}
							</span>
						))}
				</div>
				<div className={styles.stats}>
					<span>
						{' '}
						<Download /> {model.downloads}
					</span>
					<span>
						{' '}
						<Bookmark />
						{/* {bookmarks}{' '} */}
					</span>
					<span> Updated {formatDatetime(model.lastUpdated)}</span>
				</div>
			</div>
		</div>
	);
};

export default ModelCard;
