import { Amplify, Storage } from 'aws-amplify'; // can get {Auth} as well,  {Storage}
import config from 'src/config';

export const configureAmplify = (env: 'local' | 'dev' | 'stage' | 'sandbox' | 'production') => {
	Amplify.configure({
		Auth: {
			identityPoolId: config[env].cognito.IDENTITY_POOL_ID,
			region: config[env].cognito.REGION,
			userPoolId: config[env].cognito.USER_POOL_ID, // "us-east-1_5aw3rGRW5",
			mandatorySignIn: true,
			userPoolWebClientId: config[env].cognito.APP_CLIENT_ID, // "2fsdpn8chidgieor8q7qt6ra3v"
		},
		Storage: {
			AWSS3: {
				bucket: config[env].s3Bucket.BUCKET,
				region: config[env].s3Bucket.REGION,
				identityPoolId: config[env].cognito.IDENTITY_POOL_ID,
			},
		},
	});
};

export async function uploadFilesS3(prefix: string, filename: string, file: any) {
	const serverSideEncryption = 'AES256';
	try {
		const ret = await Storage.put(filename, file, {
			serverSideEncryption,
			// contentType: 'text/plain',
			customPrefix: {
				public: prefix,
			},
		});
		return ret;
	} catch (error) {
		return error;
	}
}

// could add another argument for prefix when user uploads item instead of image
// filename assumed already hashed
