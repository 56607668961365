import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Logo from 'src/assets/logo/full_logo_white.svg';

import styles from './NavbarPostLogin.module.css';

// eslint-disable-next-line no-unused-vars
export const NavbarPostLogin: React.FC = () => {
	const history = useHistory();

	return (
		<div className={styles.navbar__wrapper}>
			<div className={styles.navbar__inner}>
				<div className={styles.left__side}>
					<div className={styles.neuraml__container}>
						<img
							className={styles.logo}
							src={Logo}
							alt="Logo"
							onClick={() => {
								history.push('/');
							}}
						/>
					</div>
				</div>
				<div className={styles.search__bar}>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
							stroke="white"
							strokeOpacity="0.7"
							strokeWidth="1.33"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M14.0001 14.0001L11.1001 11.1001"
							stroke="white"
							strokeOpacity="0.7"
							strokeWidth="1.33"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<input type="text" placeholder="Type a command or search...." className={styles.search__input} />
				</div>
				<div className={cx(styles.right__side)}>
					<div
						className={styles.right__side__element}
						onClick={() => {
							history.push('/settings');
						}}
					>
						+ New Model
					</div>
					<div
						className={styles.right__side__element}
						onClick={() => {
							history.push('/developer');
						}}
					>
						Developer
					</div>
					<div
						className={styles.right__side__element}
						onClick={() => {
							history.push('/settings');
						}}
					>
						Account
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavbarPostLogin;

NavbarPostLogin.defaultProps = { displayDropdown: null };
