/* eslint-disable import/no-cycle */
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import storage from 'redux-persist/lib/storage';
import companyReducer from 'src/slices/companySlice';
import flowReducer from 'src/slices/flowSlice';
// import slices
import modelReducer from 'src/slices/modelSlice';
import userReducer from 'src/slices/userSlice';

// initialize reducer
const userPersistConfig = { key: 'user', storage };
const userPersistReducer = persistReducer(userPersistConfig, userReducer);

// company
const companyPersistConfig = { key: 'company', storage, blacklist: ['webhook'] };
const companyPersistReducer = persistReducer(companyPersistConfig, companyReducer);

// flow state
const flowPersistConfig = { key: 'flow', storage };
const flowPersistReducer = persistReducer(flowPersistConfig, flowReducer);

// model state
const modelPersistConfig = { key: 'model', storage };
const modelPersistReducer = persistReducer(modelPersistConfig, modelReducer);

export const store = configureStore({
	reducer: {
		user: userPersistReducer,
		company: companyPersistReducer,
		flow: flowPersistReducer,
		model: modelPersistReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				// Ignore these action types
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // TODO: SHOULD NOT NEED TO DO THIS
				// Ignore these field paths in all actions
				ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
				// Ignore these paths in the state
				ignoredPaths: ['items.dates'],
			},
		}),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
