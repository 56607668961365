import React from 'react';
import NavbarPostLogin from 'src/features/Shared/NavbarPostLogin';
import SceneTitle from 'src/features/Shared/SceneTitle';
import Sidebar from 'src/features/Shared/Sidebar';

import 'src/features/Shared/CSS/bodycss.css';

import styles from './IntegrationsScene.module.css';

export const IntegrationsScene: React.FC = () => (
	<div className="root">
		<NavbarPostLogin />
		<div className="root_body">
			<Sidebar />

			<div className="component__container">
				<div className="component_body">
					<SceneTitle title="Integrations" />
					<div className={styles.subtitle}>Coming Soon.</div>
				</div>
			</div>
		</div>
	</div>
);

export default IntegrationsScene;
