import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
// Material-UI alerts
import Alert from '@material-ui/lab/Alert';
import { Auth } from 'aws-amplify';
// axios
import axios from 'axios';
import cx from 'classnames';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
// remove later
import FullLogo from 'src/assets/logo/logo_black.svg';
import RingsIcon from 'src/assets/rings.svg';
import { formatCognitoError, getAPI, querystring } from 'src/Libs/helper';
import { MILLSECONDSINSECOND, MINARRAYLEN, MINPASSLEN, SECONDSINMINUTE } from 'src/Libs/magicConst';
import { selectCompany, setCompany } from 'src/slices/companySlice';
// redux
import { selectUser, setIam, setIsAuthenticated, setUser, setUserEmail, setUserId } from 'src/slices/userSlice';

import styles from './Login.module.css';

export const Login: React.FC = () => {
	const user = useAppSelector(selectUser);
	const history = useHistory();
	const company = useAppSelector(selectCompany);

	useEffect(() => {
		if (user.isAuthenticated && company.companyId !== '' && user.userId !== '') {
			// TODO: add alert that user is logged in
			const redirect = querystring('redirect');

			history.push(redirect === '/' || redirect === null ? '/' : redirect);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const dispatch = useAppDispatch();
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	// extra in case still need to set up MFA:
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [isError, setIsError] = useState<boolean>(false);

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		setIsLoading(true);
		Auth.signIn(email, password)
			.then((response) => {
				setIsLoading(false);
				const userId = response.signInUserSession.idToken.payload.sub;
				const emailLocal = response.signInUserSession.idToken.payload.email;
				const emailVerified = response.signInUserSession.idToken.payload.emailVerified;
				const jwtToken = response.signInUserSession.accessToken.jwtToken;

				handlePostLogin(userId, emailLocal, emailVerified, jwtToken);
			})
			.catch((error) => {
				setErrorMessage(formatCognitoError(error.message));
				setIsError(true);
				setIsLoading(false); // setErrorSeverity(error['severity']);
			});
	};

	function validateForm(): boolean {
		return email.length > MINARRAYLEN && password.length >= MINPASSLEN;
	}

	const renderForm = () => (
		<div className={styles.container}>
			<div className={styles.header__wrapper}>
				<img src={FullLogo} className={styles.logo} alt="asset" />
			</div>
			{isError && (
				<Alert severity="error" className={styles.alert__message}>
					{errorMessage}
				</Alert>
			)}
			<form className={styles.form} onSubmit={handleSubmit}>
				<div className={styles.form__header__wrapper}>
					<div className={styles.form__header__title}>Welcome to neuraml.</div>
				</div>
				<div>
					<div className={styles.field__label}> Email Address </div>
					<input
						className={styles.section__input}
						autoComplete="off"
						type="email"
						name="email"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<div>
					<div className={styles.field__label}> Password </div>
					<input
						className={styles.section__input}
						autoComplete="off"
						type="password"
						name="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>

				<div className={cx(styles.button__container)}>
					<button type="submit" disabled={!validateForm()} id="loaderbutton" className={styles.form__button}>
						{isLoading ? <img src={RingsIcon} alt="loading" /> : 'Login'}
					</button>
				</div>
				<div className={styles.login__links}>
					<Link id="center" to="/resetpassword" className={cx(styles.field__label, styles.login__link)}>
						Forgot password?
					</Link>
					{/* <Link id="center" to="/signup" className={cx(styles.field__label, styles.login__link)}>
						{`Don't have an account? Sign up.`}
					</Link> */}
				</div>
			</form>
		</div>
	);

	const handlePostLogin = (userId: string, emailArg: string, emailVerified: string, jwtToken: string) => {
		axios
			.get(getAPI('auth', 'enabled'), { headers: { Authorization: `Bearer ${jwtToken}` } })
			.then((res) => {
				// /////////////////////////////////////////////// call primary apis for treasury START
				dispatch(setIsAuthenticated({ isAuthenticated: true }));
				dispatch(
					setIam({
						iam: res.data,
					})
				);
				const epochConverted =
					new Date().getTime() - new Date().getTimezoneOffset() * SECONDSINMINUTE * MILLSECONDSINSECOND;
				axios
					.get(`${getAPI('auth', 'info')}?end_epoch=${epochConverted}`, {
						headers: { Authorization: `Bearer ${jwtToken}` },
					})
					.then((response) => {
						// can now dispatch everything
						const responseCompany = response.data.company;
						const responseUser = response.data.user;

						dispatch(
							setUser({
								email: responseUser.email,
								firstName: responseUser.firstName,
								lastName: responseUser.lastName,
								userId: responseUser.userId,
								// not being used
								addedBy: responseUser.emailAddedBy,
								emailVerified: responseUser.emailVerified,
								personPhoneNumber: responseUser.personPhoneNumber,
								personRelationship: responseUser.personRelationship,
								role: responseUser.userRole,
							})
						);

						dispatch(
							setCompany({
								companyId: responseCompany.companyId,
								companyName: responseCompany.companyName,

								// not being used
								companyAddress1: responseCompany.companyAddress1,
								companyAddress2: responseCompany.companyAddress2,
								companyCity: responseCompany.companyCity,
								companyCountry: responseCompany.companyCountry,
								companyUSState: responseCompany.companyUsState,
								companyWebsite: responseCompany.companyWebsite,
								companyZipcode: responseCompany.companyZipcode,
								companyLegalName: responseCompany.legalBusinessName,
							})
						);

						setIsLoading(false);
						setIsError(false);
						setErrorMessage('');
						const redirect = querystring('redirect');

						history.push(redirect === '/' || redirect === null ? '/' : redirect);
					})
					.catch(() => {
						// TODO: MAJOR TIME
						setIsLoading(false);
						setIsError(true);
						setErrorMessage('Unable to login. Please contact support@neuraml.ai');
					});
				// /////////////////////////////////////////////// call primary apis for treasury END
				// METRICS 6 requests --> should be batched to 1-2 in the future
				// axios
				// 	.get(`${getAPI('auth', 'metricsApiRequests')}?end_epoch=${epochConverted}&hist_type=7d`, {
				// 		headers: { Authorization: `Bearer ${jwtToken}` },
				// 	})
				// 	.then((response) => {
				// 		dispatch(setApiRequestMetrics({ apiRequestMetric: response.data }));
				// 	});
			})
			.catch(() => {
				// user is not allowed
				dispatch(setIsAuthenticated({ isAuthenticated: true }));
				dispatch(setUserId({ userId: userId }));
				dispatch(
					setUserEmail({
						email: emailArg,
						emailVerified: emailVerified,
					})
				);
			});
	};

	return <div>{renderForm()}</div>;
};

export default Login;
