// eslint-disable @typescript-eslint/restrict-plus-operands
import cx from 'classnames';
import * as d3 from 'd3';
import React from 'react';
import { chartType } from 'src/Libs/types';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import NoErrorsIcon from 'src/assets/no-errors.svg';
import NavbarPostLogin from 'src/features/Shared/NavbarPostLogin';
import SceneTitle from 'src/features/Shared/SceneTitle';
import Sidebar from 'src/features/Shared/Sidebar';
import { selectCompany } from 'src/slices/companySlice';
import { selectFlow, setDeveloperTemporalFilter } from 'src/slices/flowSlice';

import 'src/features/Shared/CSS/bodycss.css';

import DeveloperChart from '../DeveloperChart/DeveloperChart';

import styles from './DeveloperOverview.module.css';

export const DeveloperOverview: React.FC = () => {
	const flowSlice = useAppSelector(selectFlow);
	const dispatch = useAppDispatch();
	const companyData = useAppSelector(selectCompany);

	const charts: chartType[] = ['API Requests', 'API Error Distribution', 'Webhooks', 'Webhooks Response Time'];

	const renderTemporalButtons = () => (
		<div className={styles.temporal__root}>
			<div
				className={cx(styles.temporal__button, {
					[styles.temporal__button__selected]: flowSlice.developerTemporalFilter === '4h',
				})}
				onClick={() => {
					dispatch(setDeveloperTemporalFilter({ developerTemporalFilter: '4h' }));
				}}
			>
				4h
			</div>
			<div
				className={cx(styles.temporal__button, {
					[styles.temporal__button__selected]: flowSlice.developerTemporalFilter === '24h',
				})}
				onClick={() => {
					dispatch(setDeveloperTemporalFilter({ developerTemporalFilter: '24h' }));
				}}
			>
				24h
			</div>
			<div
				className={cx(styles.temporal__button, {
					[styles.temporal__button__selected]: flowSlice.developerTemporalFilter === '1w',
				})}
				onClick={() => {
					dispatch(setDeveloperTemporalFilter({ developerTemporalFilter: '1w' }));
				}}
			>
				1w
			</div>
		</div>
	);
	const renderNoErrors = () => (
		<div className={styles.no__errors__root}>
			<img src={NoErrorsIcon} alt="no recent errors" className={styles.no__errors__icon} />
			<div className={styles.no__errors__title}> Everything looks good. </div>
			<div className={styles.no__errors__subtitle}> We found no errors in the past 7 days </div>
		</div>
	);

	const renderChartSubTile = (chartName: chartType) => {
		if (chartName === 'API Requests') {
			return (
				<div className={styles.subtitle__root}>
					<div className={styles.subtitle__item}>
						<div>Successful</div>
						<div>{d3.sum(companyData.apiRequestMetric, (d) => d.totalSuccess)}</div>
					</div>
					<div className={styles.subtitle__item}>
						<div>Failed</div>
						<div>{d3.sum(companyData.apiRequestMetric, (d) => d.totalFail)}</div>
					</div>
				</div>
			);
		}
		if (chartName === 'Webhooks') {
			return (
				<div className={styles.subtitle__root}>
					<div className={styles.subtitle__item}>
						<div>Successful</div>
						<div>{d3.sum(companyData.webhookRequestMetric, (d) => d.totalSuccess)}</div>
					</div>
					<div className={styles.subtitle__item}>
						<div>Failed</div>
						<div>{d3.sum(companyData.webhookRequestMetric, (d) => d.totalFail)}</div>
					</div>
				</div>
			);
		}
		if (chartName === 'API Error Distribution') {
			return (
				<div className={styles.subtitle__root}>
					<div className={styles.subtitle__item}>
						<div>POST</div>
						<div>{d3.sum(companyData.apiRequestMetric, (d) => d.totalPost)}</div>
					</div>
					<div className={styles.subtitle__item}>
						<div>PATCH</div>
						<div>{d3.sum(companyData.apiRequestMetric, (d) => d.totalPatch)}</div>
					</div>
					<div className={styles.subtitle__item}>
						<div>DELETE</div>
						<div>{d3.sum(companyData.apiRequestMetric, (d) => d.totalDelete)}</div>
					</div>
				</div>
			);
		}
		return (
			<div className={styles.subtitle__root}>
				{`${
					companyData.webhookRequestMetric.filter((d) => d.avgResponseTime > 0).length > 0
						? d3.sum(companyData.webhookRequestMetric, (d) => d.avgResponseTime) /
						  companyData.webhookRequestMetric.filter((d) => d.avgResponseTime > 0).length
						: 0
				} Average`}
			</div>
		);
	};
	const getWebhookAgg = () => {
		const ret = [];
		for (let i = 0; i < companyData.webhookRequestMetric.length; i += 1) {
			ret.push({
				date: companyData.webhookRequestMetric[i].date,
				totalRequests:
					companyData.webhookRequestMetric[i].totalSuccess + companyData.webhookRequestMetric[i].totalFail,
			});
		}
		return ret;
	};
	const getApiRequestAgg = () => {
		const ret = [];
		for (let i = 0; i < companyData.apiRequestMetric.length; i += 1) {
			ret.push({
				date: companyData.apiRequestMetric[i].date,
				totalRequests: companyData.apiRequestMetric[i].totalSuccess + companyData.apiRequestMetric[i].totalFail,
			});
		}
		return ret;
	};
	const getDataKey = (chartName: chartType): string | null => {
		if (chartName === 'API Error Distribution') return 'totalFail';
		if (chartName === 'API Requests') return 'totalRequests';
		if (chartName === 'Webhooks') return 'totalRequests';
		if (chartName === 'Webhooks Response Time') return 'avgResponseTime';
		return null;
	};
	const getChartData = (chartName: chartType): any[] | null => {
		if (chartName === 'API Error Distribution') return companyData.apiRequestMetric;
		if (chartName === 'API Requests') return getApiRequestAgg();
		if (chartName === 'Webhooks') return getWebhookAgg();
		if (chartName === 'Webhooks Response Time') return companyData.webhookRequestMetric;
		return null;
	};

	return (
		<div className="root">
			<NavbarPostLogin />

			<div className="root_body">
				<Sidebar />

				<div className="component__container">
					<div className="component_body">
						<SceneTitle title="Monitoring Integrations" />
						{renderTemporalButtons()}

						<div className={styles.chart__container}>
							{charts.map((chartName, idx: number) => (
								<div className={styles.chart__root} key={`chart${idx}`}>
									<div className={styles.chart__title}>{chartName}</div>
									{renderChartSubTile(chartName)}
									<DeveloperChart data={getChartData(chartName)} dataKey={getDataKey(chartName)} />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DeveloperOverview;
