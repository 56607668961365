import React from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts';
import { MINARRAYLEN } from 'src/Libs/magicConst';

import CustomRechartTooltip from '../CustomRechartTooltip';

import styles from './DeveloperChart.module.css';

interface Props {
	dataKey: string | null;
	data: any[] | null;
}

export const DeveloperChart: React.FC<Props> = ({ dataKey, data }) => {
	if (dataKey === null) return null;
	if (data === null || data === undefined || data.length === MINARRAYLEN) return null;
	const yValues: number[] = data.map((d) => d[dataKey]);
	const maxValue = Math.max(...yValues);
	const minValue = Math.min(...yValues);
	const PADTOP = 1.1;
	const PADBOTTOM = 0.5;

	return (
		<div>
			<div className={styles.chart__container}>
				<ResponsiveContainer
					width="100%"
					height={dataKey === 'issues' ? 245 : 200}
					// https://github.com/recharts/recharts/issues/1770
					debounce={300}
					className={styles.test}
				>
					<LineChart
						margin={{
							top: 0,
							right: 0,
							bottom: 0,
							left: 0,
						}}
						data={data}
						className={styles.test__container}
					>
						<Tooltip content={<CustomRechartTooltip />} wrapperStyle={{ outline: 'none' }} />
						<YAxis hide dataKey={dataKey} domain={[minValue * PADBOTTOM - 0.15, maxValue * PADTOP]} />

						<Line
							dataKey={dataKey}
							stroke="#155eef"
							type="linear"
							strokeWidth={2}
							activeDot={{ r: 4 }}
							dot={false}
						/>
					</LineChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
};

export default DeveloperChart;
