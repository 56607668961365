/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* global localStorage */
import axios from 'axios';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { convertDatetimeEpoch, env, formatUser, getAPI } from 'src/Libs/helper';
import { MINARRAYLEN } from 'src/Libs/magicConst';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import rings from 'src/assets/rings.svg';
import config from 'src/config';
import { selectCompany } from 'src/slices/companySlice';
import { developerApiKeyInterface, selectUser, setDeveloperApiKeys, setOpenRevokeModal } from 'src/slices/userSlice';

import styles from './RevokeApiKeyModal.module.css';

interface Props extends developerApiKeyInterface {
	createSuccess: (errMessage: string) => void;
	createError: (errMessage: string) => void;
	clearMessages: () => void;
}

export const RevokeApiKeyModal: React.FC<Props> = ({
	datetimeCreated,
	apiKey,
	apiKeyName,
	internalApiKeyId,
	createSuccess,
	createError,
	clearMessages,
}) => {
	const [revokeConfirm, setRevokeConfirm] = useState<boolean>(false);

	useEffect(() => {
		clearMessages();
	}, []);

	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	const user = useAppSelector(selectUser);
	const company = useAppSelector(selectCompany);
	const accessToken: string =
		localStorage[
			`CognitoIdentityServiceProvider.${config[env].cognito.APP_CLIENT_ID}.${formatUser(user.userId)}.accessToken`
		];

	const revokeApiKey = () => {
		clearMessages();
		setIsLoading(true);

		axios
			.delete(
				`${getAPI(
					'auth',
					'apiKeys'
				)}?internal_api_key_id=${internalApiKeyId}&api_key_name=${apiKeyName}&user_email=${user.email}`,
				{
					headers: { Authorization: `Bearer ${accessToken}` },
				}
			)
			.then((response) => {
				setIsLoading(false);
				setIsDisabled(true);
				createSuccess(response.data);

				axios
					.get(getAPI('auth', 'apiKeys'), { headers: { Authorization: `Bearer ${accessToken}` } })
					.then((res) => {
						const apiKeys = res.data;

						if (typeof apiKeys !== 'object') {
							// typof [] === 'object
							/*
							 * internal error
							 * console.log('internal error with apiKeys', apiKeys, company.companyId);
							 */
						} else if (apiKeys.length === MINARRAYLEN) {
							dispatch(setDeveloperApiKeys({ developerApiKeys: [] }));
						} else {
							dispatch(setDeveloperApiKeys({ developerApiKeys: apiKeys }));
						}
					})
					.catch();

				dispatch(setOpenRevokeModal({ openRevokeModal: 'false' }));
			})
			.catch(() => {
				setIsLoading(false);
				setIsDisabled(false);
				createError('Unable to revoke Api Key at this time. Please try again later');
			});
	};

	return (
		<div className={styles.root}>
			<div className={styles.title}> Revoke API Key </div>

			<div className={styles.record}>
				<div className={styles.record__label}>Name: </div>
				<div>{apiKeyName}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>Key: </div>
				<div>{apiKey}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>Date Created: </div>
				<div>
					{new Date(convertDatetimeEpoch(datetimeCreated)).toLocaleString(navigator.language, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						hour: '2-digit',
						minute: '2-digit',
					})}
				</div>
			</div>

			<div className={styles.title}> Permissions </div>
			<div> Full Access </div>
			{revokeConfirm ? (
				<div className={styles.button__container}>
					<div
						className={cx(styles.revoke__button, styles.revoke__button__confirm, {
							[styles.revoke__button__confirm__disabled]: isLoading || isDisabled,
						})}
						onClick={() => revokeApiKey()}
					>
						{isLoading ? <img src={rings} alt="loading" /> : 'Confirm'}
					</div>
					<div
						className={cx(styles.revoke__button, styles.revoke__button__cancel, {
							[styles.revoke__button__cancel__disabled]: isLoading || isDisabled,
						})}
						onClick={() => {
							if (!isLoading && !isDisabled) setRevokeConfirm(false);
						}}
					>
						Cancel
					</div>
				</div>
			) : (
				<div className={styles.button__container}>
					<div className={styles.revoke__button} onClick={() => setRevokeConfirm(true)}>
						Revoke
					</div>
				</div>
			)}
		</div>
	);
};

export default RevokeApiKeyModal;
