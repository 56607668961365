// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface kvInterface {
	[key: string]: string;
}
interface bucketInterface {
	BUCKET: string;
	REGION: string;
}

interface apigwInterface {
	auth: kvInterface;
	team: kvInterface;
	apprunner: kvInterface;
	// documents: kvInterface;
}
interface cognitoInterface {
	comment: string;
	REGION: string;
	USER_POOL_ID: string;
	APP_CLIENT_ID: string;
	IDENTITY_POOL_ID: string;
}
interface envInterface {
	s3Bucket: bucketInterface;
	cognito: cognitoInterface;
}

interface configInterface {
	APPLICATION: string;
	REGION: string;
	prodSite: string;
	s3: kvInterface;
	local: envInterface;
	dev: envInterface;
	stage: envInterface;
	sandbox: envInterface;
	production: envInterface;
	apiGateway: apigwInterface;
}

const config: configInterface = {
	APPLICATION: 'neuraml',
	REGION: 'us-east-1',
	prodSite: 'https://neuraml.ai',
	s3: {},
	local: {
		s3Bucket: {
			BUCKET: `neuraml-backend-infra/s3data-dev`,
			REGION: 'us-east-1',
		},
		cognito: {
			comment: 'to get identity pool id: aws cognito-identity list-identity-pools --max-results 10',
			REGION: 'us-east-1',
			USER_POOL_ID: 'us-east-1_7OixtV5cN',
			APP_CLIENT_ID: '133nt60hris84pvfko4j9apevb',
			IDENTITY_POOL_ID: 'us-east-1:f6872af5-0112-4dd5-af25-b49fc4b0d9cb',
		},
	},
	dev: {
		s3Bucket: {
			BUCKET: 'neuraml-backend-infra/s3data-dev',
			REGION: 'us-east-1',
		},
		cognito: {
			comment: 'to get identity pool id: aws cognito-identity list-identity-pools --max-results 10',
			REGION: 'us-east-1',
			USER_POOL_ID: 'us-east-1_7OixtV5cN',
			APP_CLIENT_ID: '133nt60hris84pvfko4j9apevb',
			IDENTITY_POOL_ID: 'us-east-1:f6872af5-0112-4dd5-af25-b49fc4b0d9cb',
		},
	},
	stage: {
		s3Bucket: { BUCKET: '', REGION: '' },
		cognito: { comment: '', APP_CLIENT_ID: '', IDENTITY_POOL_ID: '', REGION: '', USER_POOL_ID: '' },
	},
	sandbox: {
		s3Bucket: { BUCKET: '', REGION: '' },
		cognito: { comment: '', APP_CLIENT_ID: '', IDENTITY_POOL_ID: '', REGION: '', USER_POOL_ID: '' },
	},
	production: {
		s3Bucket: { BUCKET: '', REGION: '' },
		cognito: { comment: '', APP_CLIENT_ID: '', IDENTITY_POOL_ID: '', REGION: '', USER_POOL_ID: '' },
	},
	apiGateway: {
		auth: {
			enabled: '/auth/enabled',
			info: '/auth/info',
			joinEmailList: '/auth/join-emaillist',
			apiKeys: '/auth/apikeys',
			signupSession: '/auth/signup-session',
			postSignupForm: '/auth/post-signup-form',
			logs: '/auth/logs',
			// metrics
			metrics: '/auth/metrics',
			metricsApiRequests: '/auth/metrics/api-requests',
			metricsWebhookRequests: '/auth/metrics/webhook-requests',
			// actual
		},
		team: {
			updateUserAccess: '/team/update-user-access',
			users: '/team/',
			disableUser: '/team/disable-user',
			enableUser: '/team/enable-user',
			deleteUser: '/team/delete-user',
			searchUsers: '/team/search-users',
		},
		apprunner: {
			listModels: '/models',
			downloadModel: '/models/:model_id/download',
		},
	},
};

export default config;
