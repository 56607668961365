/* eslint-disable   */
/* global localStorage  */
import { useEffect } from 'react';
// Routing
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import styles from 'src/App.module.css';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import config from 'src/config';
import {
	camelToSnakeCase,
	convertObjectCamelSnake,
	env,
	FORBIDDEN,
	getAPI,
	logoutChannel,
	snakeToCamelCase,
	// snakeToCamelCase,
	UNAUTHORIZED,
} from 'src/Libs/helper';
// import scenes
import ForgotPassword from 'src/scenes/Auth/ForgotPassword';
import LoginScene from 'src/scenes/Auth/Login';
import NotFound from 'src/scenes/NotFound';
// redux
import { selectUser, setIam, setIsAuthenticated, setUser } from 'src/slices/userSlice';

import SignupStartApp from './features/Auth/SignupStartApp';

import useSignOutUser from './hooks/useSignOutUser';
import { MILLSECONDSINSECOND, SECONDSINMINUTE } from './Libs/magicConst';
// LOGOUT STUFF
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Teamscene from 'src/scenes/Team';
import ApiKeys from './features/Developer/ApiKeys';
import DeveloperOverview from './features/Developer/DeveloperOverview';
import Logs from './features/Developer/Logs';
import Webhook from './features/Developer/Webhook';
import AuthenticatedRoute from './features/Routes/AuthenticatedRoute';
import CompanySettings from './features/Settings/CompanySettings';
import ProfileSettings from './features/Settings/ProfileSettings';
import WebhookInformationScene from './scenes/Developer/WebhookInformationScene';
import HomeOverviewScene from './scenes/Home/HomeOverviewScene';
import ReportsScene from './scenes/Home/ReportsScene';
import ModelScene from './scenes/Model';
import IntegrationsScene from './scenes/Settings/IntegrationsScene';
import { selectCompany } from './slices/companySlice';

declare global {
	interface Window {
		primeTrustReady: any;
		pt: any;
	}
}

function App() {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectUser);
	const signOutUser = useSignOutUser();
	const company = useAppSelector(selectCompany);

	/*
	 * Configure axios
	 * CamelCase -> snake_case for outgoing request
	 * snake_case -> CamelCase for incoming response
	 * NOTE: must use useeffect, otherwise will listen to event multiple times
	 * putting interceptor block inside useffect prevents it from being called twice (the interceptor, not the actual request)
	 * but this also means that requests made on render (inside a useeffect elsewhere for example)
	 * do not get caught in this interceptor
	 */
	axios.interceptors.request.use(
		(request): InternalAxiosRequestConfig<any> => {
			if (request.url?.includes('neuraml') || request.url?.includes('localhost'))
				request.data = convertObjectCamelSnake(request.data, camelToSnakeCase);
			return request;
		},
		(error): Promise<never> => {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		(response): AxiosResponse<any, any> => {
			if (response.config.url?.includes('neuraml') || response.config.url?.includes('localhost'))
				response.data = convertObjectCamelSnake(response.data, snakeToCamelCase);
			return response;
		},
		(error): Promise<never> => {
			try {
				if (
					error.response?.status === UNAUTHORIZED &&
					(error.response?.data?.message === 'Unauthorized' || error.response?.statusText === 'Unauthorized')
				) {
					logoutChannel.postMessage({ logoutMessage: 'logout' });
					signOutUser();
				}
			} catch {
				return Promise.reject(error);
			}
			return Promise.reject(error);
		}
	);

	useEffect(() => {
		logoutChannel.onmessage = (event) => {
			if (event.logoutMessage === 'logout') {
				signOutUser();
				// logoutChannel.close(); TODO -- INTRODUCES SMALL ISSUE
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// issues requests
	useEffect(
		() => {
			const accessToken: string =
				localStorage[
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`CognitoIdentityServiceProvider.${config[env].cognito.APP_CLIENT_ID}.${user.userId}.accessToken`
				];

			if (accessToken === undefined || company.companyId === '') {
				// dispatch(setIsAuthenticated({ isAuthenticated: false }));
				return;
			}
			axios
				.get(getAPI('auth', 'enabled'), { headers: { Authorization: `Bearer ${accessToken}` } })
				.then((res) => {
					dispatch(setIsAuthenticated({ isAuthenticated: true }));
					dispatch(
						setIam({
							iam: res.data,
						})
					);
				})
				.catch((error) => {
					if (axios.isAxiosError(error) && error.response?.status === FORBIDDEN) {
						dispatch(setIsAuthenticated({ isAuthenticated: true }));
					}
				});

			const epochConverted =
				new Date().getTime() - new Date().getTimezoneOffset() * SECONDSINMINUTE * MILLSECONDSINSECOND;
			axios
				.get(`${getAPI('auth', 'info')}?end_epoch=${epochConverted}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				})
				.then((response) => {
					// can now dispatch everything
					const responseCompany = response.data.company;
					const responseUser = response.data.user;

					dispatch(
						setUser({
							email: responseUser.email,
							firstName: responseUser.firstName,
							lastName: responseUser.lastName,
							userId: responseUser.userId,
						})
					);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[user.userId, company.companyId]
	);

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{
				<div className={styles.app}>
					<Router>
						<div className={styles.app__body}>
							{/* Should on click anywhere close dropdown */}
							<Switch>
								{/* Home */}
								<AuthenticatedRoute exact path="/" Component={HomeOverviewScene} />
								<AuthenticatedRoute exact path="/model/:modelId" Component={ModelScene} />
								<AuthenticatedRoute exact path="/reports" Component={ReportsScene} />
								{/* settings */}
								<AuthenticatedRoute exact path="/settings" Component={CompanySettings} />
								<AuthenticatedRoute exact path="/profile-settings" Component={ProfileSettings} />
								<AuthenticatedRoute exact path="/team" Component={Teamscene} />
								<AuthenticatedRoute exact path="/integrations" Component={IntegrationsScene} />
								{/* developer */}
								<AuthenticatedRoute exact path="/developer" Component={DeveloperOverview} />
								<AuthenticatedRoute exact path="/apikeys" Component={ApiKeys} />
								<AuthenticatedRoute exact path="/webhooks" Component={Webhook} />
								<AuthenticatedRoute
									exact
									path="/webhooks/:webhookId"
									Component={WebhookInformationScene}
								/>
								<AuthenticatedRoute exact path="/logs" Component={Logs} />

								<Route exact={false} path="/login">
									<LoginScene />
								</Route>
								<Route exact path="/signup">
									<SignupStartApp />
								</Route>

								<Route exact path="/resetpassword">
									<ForgotPassword />
								</Route>

								<Route>
									<NotFound />
								</Route>
							</Switch>
						</div>
					</Router>
				</div>
			}
		</>
	);
}
export default App;
