import React from 'react';

import styles from './SceneTitle.module.css';

interface Props {
	title: string;
}
// NOTE: No longer required after moving title to navbar.
// Just used as extra component (in team scene for example)
export const SceneTitle: React.FC<Props> = ({ title }) => <div className={styles.scene_title}> {title} </div>;

export default SceneTitle;
