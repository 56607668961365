import React, { useEffect, useState } from 'react';
// import DatePicker from 'react-datepicker';
// import CalendarIconSVG from 'src/assets/calendar.svg';
import NavbarPostLogin from 'src/features/Shared/NavbarPostLogin';
import Sidebar from 'src/features/Shared/Sidebar';
import { MINARRAYLEN } from 'src/Libs/magicConst';

import 'src/features/Shared/CSS/bodycss.css';

import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import config from 'src/config';
import ModelCard from 'src/features/Model/ModelCard';
import { env, formatUser, getAPI } from 'src/Libs/helper';
import { selectCompany } from 'src/slices/companySlice';
import { selectFlow } from 'src/slices/flowSlice';
import { ModelBasicInfo, selectModel, setModelDataList } from 'src/slices/modelSlice';
import { selectUser } from 'src/slices/userSlice';
import styles from './HomeOverviewScene.module.css';

export const HomeOverviewScene: React.FC = () => {
	const user = useAppSelector(selectUser);
	const company = useAppSelector(selectCompany);
	const flowState = useAppSelector(selectFlow);
	const modelState = useAppSelector(selectModel);
	const dispatch = useAppDispatch();

	const [modelListFiltered, setModelListFiltered] = useState<Array<ModelBasicInfo>>([]);

	const accessToken: string =
		localStorage[
			`CognitoIdentityServiceProvider.${config[env].cognito.APP_CLIENT_ID}.${formatUser(user.userId)}.accessToken`
		];

	useEffect(() => {
		axios
			.get(getAPI('apprunner', 'listModels'), { headers: { Authorization: `Bearer ${accessToken}` } })
			.then((response) => {
				const modelList = response.data;
				console.log(modelList);

				if (modelList.length === MINARRAYLEN) {
					dispatch(setModelDataList({ modelDataList: [] }));
					setModelListFiltered([]);
				} else {
					dispatch(setModelDataList({ modelDataList: modelList }));
					setModelListFiltered(modelList);
				}
			})
			.catch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company.companyId]);

	useEffect(() => {
		if (flowState.sidebarFilters === null || flowState.sidebarFilters === '')
			setModelListFiltered(modelState.modelDataList);
		else
			setModelListFiltered(
				modelState.modelDataList.filter((model) => model.modelTags.includes(flowState.sidebarFilters))
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flowState.sidebarFilters]);

	const renderModelCards = () => {
		return modelListFiltered.map((model) => {
			return (
				<div key={model.modelId}>
					<br />
					<ModelCard model={model} key={model.modelId} />
				</div>
			);
		});
	};

	return (
		<div className="root">
			<NavbarPostLogin />
			<div className="root_body">
				<Sidebar />
				<div className="component__container">
					<div className={styles.model__container}>
						{modelState.modelDataList !== null &&
							modelState.modelDataList !== undefined &&
							modelState.modelDataList.length > 0 &&
							renderModelCards()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeOverviewScene;
