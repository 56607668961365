import cx from 'classnames';
import * as d3 from 'd3';
import React, { useEffect, useState } from 'react';
import { MINARRAYLEN } from 'src/Libs/magicConst';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import NavbarPostLogin from 'src/features/Shared/NavbarPostLogin/NavbarPostLogin';
import Sidebar from 'src/features/Shared/Sidebar';
import TeamColumnNames from 'src/features/Team/TeamColumnNames';
import TeamSearchBar from 'src/features/Team/TeamSearchBar';
import { companyPeopleInterface, selectCompany } from 'src/slices/companySlice';
import { selectFlow, setPersonView } from 'src/slices/flowSlice';

import styles from './Teamscene.module.css';

const MFABadge = () => (
	<div className={styles.badgewrapper}>
		<div className={styles.badge_inner}> 2FA (authenticator app) </div>
	</div>
);

export const Teamscene: React.FC = () => {
	const flowState = useAppSelector(selectFlow);
	const company = useAppSelector(selectCompany);
	const dispatch = useAppDispatch();

	const [companyPeopleFiltered, setCompanyPeopleFiltered] = useState<companyPeopleInterface[]>([]);
	// const [sceneTitleTitle, setSceneTitleTitle] = useState<string>('Loading...');

	useEffect(() => {
		if (
			flowState.viewTeam &&
			flowState.viewTeamEmail !== '' &&
			company.companyPeople !== undefined &&
			company.companyPeople?.length > MINARRAYLEN
		) {
			const personToView = d3.filter(company.companyPeople, (d) => d.email === flowState.viewTeamEmail);
			try {
				dispatch(setPersonView({ personView: personToView[0] }));
			} catch {}
		}
		// add JSON.stringify to compare any changes inside the array
		// this is useful here if a person is selected, and their role changes
		// then we can automatically display that in team member information
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flowState.viewTeamEmail, JSON.stringify(company.companyPeople)]);

	useEffect(() => {
		if (
			company.companyPeople !== null &&
			company.companyPeople !== undefined &&
			company.companyPeople.length > MINARRAYLEN
		) {
			setCompanyPeopleFiltered([...company.companyPeople]);
			// setSceneTitleTitle(`${company.companyPeople.length} Active Users`);
		}
	}, [company.companyPeople]);

	useEffect(() => {
		let filteredArr = [...company.companyPeople];

		// if (company.companyPeople !== undefined && flowState.personRoleFilter !== 'All Roles') {
		// 	filteredArr = d3.filter(filteredArr, (d) => d.userRole === flowState.personRoleFilter);
		// }
		if (company.companyPeople !== undefined && flowState.personFilter !== '') {
			filteredArr = d3.filter(
				filteredArr,
				(d) =>
					d.firstName?.toLowerCase().includes(flowState.personFilter) ||
					d.lastName?.toLowerCase().includes(flowState.personFilter) ||
					d.email?.toLowerCase().includes(flowState.personFilter)
			);
		}
		setCompanyPeopleFiltered([...filteredArr]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flowState.personFilter, flowState.personRoleFilter]);

	return (
		<div className="root">
			<NavbarPostLogin />

			<div className="root_body">
				<Sidebar />
				<div className="component__container">
					<div className="component_body">
						<div className={styles.container}>
							<div className={styles.container_header}>
								<div className={styles.container_header_body}>
									<div className={styles.header_title}>Team</div>
									<div className={styles.header_secondary}>
										{' '}
										Two-Step Authentication is mandatory by default. If you want to change this
										please contact support@neuraml.com
									</div>
								</div>
							</div>
							<TeamSearchBar />
							<div className={styles.container_body}>
								{!flowState.viewTeam && (
									<div className={styles.table__full}>
										{/* <div className={styles.num__issues}>{sceneTitleTitle}</div> */}
										<TeamColumnNames />
										{companyPeopleFiltered !== undefined && companyPeopleFiltered !== null && (
											<div>
												{companyPeopleFiltered.length > MINARRAYLEN &&
													companyPeopleFiltered.map((element, index) => (
														<div key={String(index) + element.email}>
															<div className={styles.table_row}>
																<div className={cx(styles.column__col, styles.member)}>
																	<div className={styles.member_name}>
																		{element.firstName}&nbsp;{element.lastName}
																	</div>
																	<div className={styles.member_email}>
																		{element.email}
																	</div>
																</div>
																<div className={cx(styles.column__col, styles.role)}>
																	Administrator <br />
																	{element.userRole}
																</div>
																<div className={cx(styles.column__col, styles.auth)}>
																	<MFABadge />
																</div>
																<div className={cx(styles.column__col, styles.role)}>
																	Nov 29, 2022, 6:02 PM
																</div>
																<div className={cx(styles.edit)}>
																	<svg
																		width="25"
																		height="25"
																		viewBox="0 0 25 25"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M12.5 13.5C13.0523 13.5 13.5 13.0523 13.5 12.5C13.5 11.9477 13.0523 11.5 12.5 11.5C11.9477 11.5 11.5 11.9477 11.5 12.5C11.5 13.0523 11.9477 13.5 12.5 13.5Z"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M19.5 13.5C20.0523 13.5 20.5 13.0523 20.5 12.5C20.5 11.9477 20.0523 11.5 19.5 11.5C18.9477 11.5 18.5 11.9477 18.5 12.5C18.5 13.0523 18.9477 13.5 19.5 13.5Z"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M5.5 13.5C6.05228 13.5 6.5 13.0523 6.5 12.5C6.5 11.9477 6.05228 11.5 5.5 11.5C4.94772 11.5 4.5 11.9477 4.5 12.5C4.5 13.0523 4.94772 13.5 5.5 13.5Z"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</div>
															</div>
														</div>
													))}
											</div>
										)}
										{(companyPeopleFiltered === undefined || companyPeopleFiltered === null) && (
											<div className={styles.empty__table}> Loading ... </div>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Teamscene;
