import React, { useState } from 'react';
import cx from 'classnames';
import { useAppDispatch } from 'src/app/hooks';
// import CarrotDownIcon from 'src/assets/carrot-down.svg';
import SearchIcon from 'src/assets/search-icon.svg';
// import Dropdown from 'src/features/Shared/React-Dropdown/dist/index.js';
import { setPersonFilter, setPersonRoleFilter } from 'src/slices/flowSlice';

// import 'react-datepicker/dist/react-datepicker.css';
import 'src/features/Shared/React-Dropdown/style.css';

import styles from './TeamSearchBar.module.css';

export const TeamSearchBar: React.FC = () => {
	const dispatch = useAppDispatch();
	const [search, setSearch] = useState<string>('');
	// const [roleDropdownClicked, setRoleDropdownClicked] = useState<boolean>(false);
	// const [roleOption, setRoleOption] = useState<string>('All');
	// const roleOptions = ['All Roles', 'Owner', 'Member']; // TODO: USER

	return (
		<div className={styles.container}>
			<div className={styles.search}>
				<div className={styles.search_auto_layout}>
					<img src={SearchIcon} className={styles.search__icon} alt="search" />
					<input
						value={search}
						onChange={(e) => {
							setSearch(e.target.value);
							dispatch(setPersonFilter({ personFilter: e.target.value.toLowerCase() }));
						}}
						className={styles.search__input}
						type="text"
						placeholder="Filter by name or email..."
						spellCheck="false"
					/>
				</div>
			</div>
			{/* <div className={cx(styles.type, { [styles.type__clicked]: roleDropdownClicked })}>
				<Dropdown
					options={roleOptions}
					onChange={(e: any) => {
						setRoleOption(e.value);
						dispatch(setPersonRoleFilter({ personRoleFilter: e.value }));
					}}
					className={styles.type__dropdown}
					value={roleOption}
					placeholder="Type"
					// arrowClassName={styles.myArrowClassName}
					onOpen={() => setRoleDropdownClicked(true)}
					onClose={() => setRoleDropdownClicked(false)}
					arrowClosed={<div />}
					arrowOpen={<div />}
				/>
				<img src={CarrotDownIcon} className={styles.carrot__icon__dropdown} alt="dropdown" />
			</div> */}
		</div>
	);
};

export default TeamSearchBar;
