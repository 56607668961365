import React from 'react';
import { useAppSelector } from 'src/app/hooks';
import HelpIcon from 'src/assets/HelpCircle.svg';
import NavbarPostLogin from 'src/features/Shared/NavbarPostLogin';
import Sidebar from 'src/features/Shared/Sidebar';
import { selectCompany } from 'src/slices/companySlice';

import 'src/features/Shared/CSS/bodycss.css';

import styles from './CompanySettings.module.css';

export const CompanySettings: React.FC = () => {
	const company = useAppSelector(selectCompany);
	return (
		<div className="root">
			<NavbarPostLogin />

			<div className="root_body">
				<Sidebar />
				<div className="component__container">
					<div className="component_body">
						<div className={styles.container}>
							<div className={styles.container_header}>
								<div className={styles.container_header_body}>
									<div className={styles.header_title}>Company Information</div>
									<div className={styles.header_secondary}>
										This information allows us to ensure that you are able to use neuraml. To edit
										or update please email support@neuraml.ai.
									</div>
								</div>
							</div>
							<div className={styles.container_body}>
								<div className={styles.container_body_cell}>
									<div className={styles.cell_header}>
										<div className={styles.cell_name}>Company Details</div>
										<img src={HelpIcon} alt="Help tooltip" />
									</div>
									<div className={styles.cell_body_wrapper}>
										<div className={styles.cell_body}>
											<div className={styles.text_and_supporting}>
												<div className={styles.company_name}>{company.companyName} Labs</div>
												<div className={styles.supporting_text}>neuraml.ai</div>
											</div>
											<div className={styles.supporting_text}>
												4546 El Camino Real B10 #660 <br /> Los Altos, CA 94022 US
											</div>
											<div className={styles.text_and_supporting}>
												<div className={styles.supporting_text_title}>
													{' '}
													Other information provided
												</div>
												<div className={styles.supporting_text}>
													{' '}
													EIN, DBA, Phone, Industry{' '}
												</div>
											</div>
										</div>
										<div className={styles.cell_standing}>
											<div className={styles.standing_message}>No updated needed.</div>
										</div>
									</div>
								</div>

								<div className={styles.container_body_cell}>
									<div className={styles.cell_header}>
										<div className={styles.cell_name}>Ownership and Management</div>
										<img src={HelpIcon} alt="Help tooltip" />
									</div>
									<div className={styles.cell_body_wrapper}>
										<div className={styles.cell_body}>
											<div className={styles.text_and_supporting}>
												<div className={styles.company_name}> John Smith </div>
												<div className={styles.supporting_text}>Owner</div>
											</div>
											<div className={styles.supporting_text}>johnsmith@acme.ai</div>
											<div className={styles.text_and_supporting}>
												<div className={styles.supporting_text_title}>
													{' '}
													Other information provided
												</div>
												<div className={styles.supporting_text}>
													{' '}
													SNN, Job title, Phone, DOB, HA{' '}
												</div>
											</div>
										</div>
										<div className={styles.cell_standing}>
											<div className={styles.standing_message}>No updated needed.</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompanySettings;
