import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { lightfair } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { convertDatetimeEpoch } from 'src/Libs/helper';
import { webhookLogInterface } from 'src/Libs/types';

import styles from './WebhookLogRecord.module.css';

interface Props {
	webhookLog: webhookLogInterface;
}

export const WebhookLogRecord: React.FC<Props> = ({ webhookLog }) => {
	const reconstructJSON = () =>
		`{\n\t"event_type": "Payments",\n\t"event_status": "${webhookLog.eventStatus}",\n\t` +
		`"issue_id": "${webhookLog.issueId}",\n\t"order_id": "${webhookLog.companyOrderId}",\n\t` +
		`"event_id": "${webhookLog.eventId}",\n` +
		'}';

	const renderMetaData = () => (
		<div className={styles.metadata__container}>
			<div className={styles.record}>
				<div className={styles.record__label}>Status</div>
				<div className={styles.record__value}>{webhookLog.eventStatus}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>ID</div>
				<div className={styles.record__value}>{webhookLog.webhookLogId}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>Time</div>
				<div className={styles.record__value}>
					{new Date(convertDatetimeEpoch(webhookLog.datetimeInserted)).toLocaleString(navigator.language, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						hour: '2-digit',
						minute: '2-digit',
					})}
				</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>IP address</div>
				<div className={styles.record__value}>{webhookLog.ipAddr !== null ? webhookLog.ipAddr : ''}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>API Version</div>
				<div className={styles.record__value}>{1.1}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>Idempotency</div>
				<div className={styles.record__value}>{webhookLog.eventId}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>Success</div>
				<div className={styles.record__value}>{webhookLog.isSuccess ? 'True' : 'False'}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>Status Code</div>
				<div className={styles.record__value}>{webhookLog.responseStatus}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>Response Time</div>
				<div className={styles.record__value}>{webhookLog.responseTimeSeconds} Seconds</div>
			</div>
		</div>
	);

	return (
		<div className={styles.container}>
			<div className={styles.webhook__url}> POST &nbsp; {webhookLog.webhookUrl}</div>
			{renderMetaData()}

			<div className={styles.webhook__url}>Request Body </div>
			<SyntaxHighlighter
				language="json"
				style={lightfair}
				wrapLongLines
				showLineNumbers
				className={styles.syntax__container}
			>
				{reconstructJSON()}
			</SyntaxHighlighter>
		</div>
	);
};

export default WebhookLogRecord;
