import React from 'react';
import cx from 'classnames';

import styles from './TeamColumnNames.module.css';

export const TeamColumnNames: React.FC = () => (
	<div className={styles.column__container}>
		<div className={styles.column__col}>Member</div>
		<div className={styles.column__col}>Role</div>

		<div className={styles.column__col}>Auth</div>
		<div className={styles.column__col}>Last Login</div>
		<div className={cx(styles.edit)}> </div>
	</div>
);

export default TeamColumnNames;
