/* global localStorage  */
import axios from 'axios';
import cx from 'classnames';
import React, { useState } from 'react';
import { convertDatetimeEpoch, env, getAPI } from 'src/Libs/helper';
import { APILogsInterface } from 'src/Libs/types';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import RingsIcon from 'src/assets/rings.svg';
import config from 'src/config';
import APILogRecord from 'src/features/Shared/APILogRecord';
import NavbarPostLogin from 'src/features/Shared/NavbarPostLogin';
import SceneTitle from 'src/features/Shared/SceneTitle';
import Sidebar from 'src/features/Shared/Sidebar';
import { appendApiLogs, selectCompany } from 'src/slices/companySlice';
import { selectUser } from 'src/slices/userSlice';

import styles from './Logs.module.css';

export const Logs: React.FC = () => {
	// loading
	const company = useAppSelector(selectCompany);
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectUser);
	const [isLoading, setIsLoading] = useState(false);
	const [logClicked, setLogClicked] = useState<APILogsInterface | null>(null);

	type logFilterType = 'All' | 'Succeeded' | 'Failed';
	const [logsFitler, setLogsFilter] = useState<logFilterType>('All');
	const allLogFilters: logFilterType[] = ['All', 'Succeeded', 'Failed'];
	const accessToken: string =
		localStorage[
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`CognitoIdentityServiceProvider.${config[env].cognito.APP_CLIENT_ID}.${user.userId}.accessToken`
		];

	const renderLoadMore = () => {
		if (logsFitler !== 'All') return null;
		return (
			<div
				className={cx(styles.load__more, { [styles.load__more__disabled]: !company.apiLogsHasMore })}
				onClick={() => {
					if (!company.apiLogsHasMore) return;
					setIsLoading(true);
					axios
						.get(`${getAPI('auth', 'logs')}?page_num=${company.apiLogsPageNum + 1}`, {
							headers: {
								Authorization: `Bearer ${accessToken}`,
							},
						})
						.then((res) => {
							dispatch(
								appendApiLogs({
									apiLogs: res.data.data,
									apiLogsHasMore: res.data.meta.hasMore,
									apiLogsResourceCount: res.data.meta.resourceCount,
									apiLogsPageNum: res.data.pageNum,
								})
							);
							setIsLoading(false);
						})
						.catch(() => {
							setIsLoading(false);
						});
				}}
			>
				{isLoading ? <img src={RingsIcon} alt="loading" /> : 'Load More'}
			</div>
		);
	};

	const renderLogs = () => {
		if (company.apiLogs === null || company.apiLogs === undefined) return null;
		return (
			<div className={styles.logs__container}>
				<div className={styles.logs__container__title}>
					{allLogFilters.map((filt, idx) => (
						<div
							key={`filter${idx}`}
							className={cx(styles.filter__element, {
								[styles.filter__element__clicked]: logsFitler === filt,
							})}
							onClick={() => setLogsFilter(filt)}
						>
							{filt}
						</div>
					))}
				</div>
				<div className={styles.logs__container__bottom}>
					<div className={styles.logs__container__bottom__left}>
						<div className={styles.col__container}>
							<div className={cx(styles.log__col, styles.log__status)}>Status</div>
							<div className={cx(styles.log__col, styles.log__type)}>Method</div>
							<div className={cx(styles.log__col, styles.log__eid)}>Resource</div>
							<div className={cx(styles.log__col, styles.log__date)}>Created</div>
							<div className={cx(styles.log__col, styles.log__req)}>Request Id</div>
						</div>
						{company.apiLogs !== null &&
							company.apiLogs !== undefined &&
							company.apiLogs
								.filter((d) =>
									logsFitler === 'All'
										? d
										: logsFitler === 'Succeeded'
										? d.responseStatusCode >= 200 && d.responseStatusCode < 300
										: d.responseStatusCode >= 300
								)
								.map((log: APILogsInterface, idx: number) => (
									<div
										className={cx(styles.row__container, {
											[styles.row__container__clicked]: log.idmKey === logClicked?.idmKey,
										})}
										key={`log${idx}`}
										onClick={() => {
											if (logClicked === null) setLogClicked(log);
											else if (logClicked.idmKey === log.idmKey) setLogClicked(null);
											else setLogClicked(log);
										}}
									>
										<div className={cx(styles.log__row, styles.log__status)}>
											<div
												className={cx(
													styles.status__element,
													{
														[styles.status__completed]:
															log.responseStatusCode >= 200 &&
															log.responseStatusCode < 300,
													},
													{ [styles.status__failed]: log.responseStatusCode >= 300 }
												)}
											>
												{`${log.responseStatusCode} ${
													log.responseStatusCode >= 200 && log.responseStatusCode < 300
														? 'OK'
														: 'ERR'
												}`}
											</div>
										</div>
										<div className={cx(styles.log__row, styles.log__type)}>{log.method}</div>
										<div className={cx(styles.log__row, styles.log__eid)}>{log.rawPath}</div>
										<div className={cx(styles.log__row, styles.log__date)}>
											{new Date(convertDatetimeEpoch(log.datetimeInserted)).toLocaleDateString(
												navigator.language,
												{
													year: 'numeric',
													month: 'short',
													day: 'numeric',
													hour: '2-digit',
													minute: '2-digit',
												}
											)}
										</div>
										<div className={cx(styles.log__row, styles.log__req)}>{log.requestId}</div>
									</div>
								))}
						{renderLoadMore()}
					</div>

					{logClicked !== null && (
						<div className={styles.log__container__bottom__right}>
							<APILogRecord apiLog={logClicked} />
						</div>
					)}
				</div>
			</div>
		);
	};

	return (
		<div className={styles.root}>
			<NavbarPostLogin />

			<div className={styles.root_body}>
				<Sidebar />
				<div className={styles.component__container}>
					<SceneTitle title="Logs" />
					{renderLogs()}
				</div>
			</div>
		</div>
	);
};

export default Logs;
