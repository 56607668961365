/* global localStorage  */
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { convertDatetimeEpoch, env, formatUser, getAPI, isValidUrl } from 'src/Libs/helper';
import {
	MAX_SHARED_SECRET_LENGTH,
	MAX_WEBHOOK_LENGTH,
	MINARRAYLEN,
	MIN_SHARED_SECRET_LENGTH,
	MODAL_OPACITY,
} from 'src/Libs/magicConst';
import { webhookEventTypes, webhookInterface, webhookLogInterface } from 'src/Libs/types';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import PaymentBarArrow from 'src/assets/arrow/issue-bar-arrow.svg';
import DotsIcon from 'src/assets/dots-horizontal.svg';
import RingsIcon from 'src/assets/rings.svg';
import WebhookIcon from 'src/assets/webhook.svg';
import XMark from 'src/assets/x-mark.svg';
import config from 'src/config';
import NavbarPostLogin from 'src/features/Shared/NavbarPostLogin';
import Sidebar from 'src/features/Shared/Sidebar';
import WebhookLogRecord from 'src/features/Shared/WebhookLogRecord';
import { selectCompany, setWebhooks } from 'src/slices/companySlice';
import { selectUser } from 'src/slices/userSlice';

import styles from './WebhookInformationScene.module.css';

export const WebhookInformationScene: React.FC = () => {
	const company = useAppSelector(selectCompany);
	const history = useHistory();
	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();

	// error
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	// success
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	// loading
	const [webhook, setWebhook] = useState<webhookInterface | null>(null);

	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [isUpdate, setIsUpdate] = useState<boolean>(false);
	const [updateWebhookUrl, setUpdateWebhookUrl] = useState<string | null>(webhook?.webhookUrl ?? null);
	const [updateWebhookSecret, setUpdateWebhookSecret] = useState<string | null>(webhook?.sharedSecret ?? null);
	const [updateWebhookEventTypes, setUpdateWebhookEventTypes] = useState<webhookEventTypes[]>(
		webhook?.eventTypes ?? []
	);
	const eventTypes: webhookEventTypes[] = ['issues'];

	const [webhookLogs, setWebhookLogs] = useState<webhookLogInterface[]>([]);
	const [hasMoreWebhookLogs, setHasMoreWebhookLogs] = useState<boolean>(false);
	const [webhookLogClicked, setWebhookLogClicked] = useState<webhookLogInterface | null>(null);

	// log
	type logFilterType = 'All' | 'Succeeded' | 'Failed';
	const [logsFitler, setLogsFilter] = useState<logFilterType>('All');
	const allLogFilters: logFilterType[] = ['All', 'Succeeded', 'Failed'];
	const TIMEOFFSET = 300000;
	const accessToken: string =
		localStorage[
			`CognitoIdentityServiceProvider.${config[env].cognito.APP_CLIENT_ID}.${formatUser(user.userId)}.accessToken`
		];

	// load webhook logs
	useEffect(() => {
		const url = window.location.href.split('/');
		const id: string = url[url.length - 1];
		const wbhObject = company.webhooks.filter((d) => d.webhookId === id);
		if (wbhObject === null || wbhObject === undefined || wbhObject.length !== 1) return;
		setWebhook(wbhObject[0]);
		axios
			.get(`${getAPI('auth', 'webhookLogs')}?webhook_id=${id}`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			})
			.then((response) => {
				setWebhookLogs(response.data.data);
				setHasMoreWebhookLogs(response.data.meta.hasMore);
			});
	}, []);

	useEffect(() => {
		if (webhook === null && webhook === undefined) return;
		setUpdateWebhookUrl(webhook?.webhookUrl ?? null);
		setUpdateWebhookSecret(webhook?.sharedSecret ?? null);
		setUpdateWebhookEventTypes(webhook?.eventTypes ?? []);
	}, [JSON.stringify(webhook)]);

	// re-load webhook
	const loadWebhook = (): void => {
		axios
			.get(getAPI('auth', 'webhooks'), { headers: { Authorization: `Bearer ${accessToken}` } })
			.then((res) => {
				dispatch(setWebhooks({ webhooks: res.data }));
				// when called from update, want to turn off edit button here
				// otherwise, there is a small glitch in changing back to old value
				// if setEditButton(false) from other function
				const wbhObject = res.data.filter((d: any) => d.webhookId === webhook?.webhookId);
				if (wbhObject === null || wbhObject === undefined || wbhObject.length !== 1) return;
				setWebhook(wbhObject[0]);
			})
			.catch(() => {
				setIsUpdateLoading(false);
				setIsError(true);
			});
	};

	const closeUpdateWebhookModal = () => {
		setIsUpdate(false);
		setUpdateWebhookEventTypes(webhook?.eventTypes ?? []);
		setUpdateWebhookSecret(webhook?.sharedSecret ?? null);
		setUpdateWebhookUrl(webhook?.webhookUrl ?? null);
	};
	const isUpdateDisabled = (): boolean =>
		webhook === null ||
		isUpdateLoading ||
		isDeleteLoading ||
		(updateWebhookUrl === webhook.webhookUrl &&
			updateWebhookSecret === webhook.sharedSecret &&
			[...updateWebhookEventTypes].sort().join('') === [...webhook.eventTypes].sort().join('')) ||
		!isValidUrl(updateWebhookUrl ?? '') ||
		((webhook.sharedSecret ?? '').length > 0 && (updateWebhookSecret ?? '').length === 0) ||
		updateWebhookEventTypes.length === 0;
	// update webhook
	const updateWebhook = (): void => {
		if (webhook === null || webhook === undefined) return;
		if (isUpdateDisabled()) return;
		setIsUpdateLoading(true);
		setIsSuccess(false);
		setIsError(false);
		const updateDict: any = { webhookId: webhook.webhookId };
		if (updateWebhookUrl !== null && updateWebhookUrl !== undefined) updateDict.webhookUrl = updateWebhookUrl;
		if (
			updateWebhookSecret !== null &&
			updateWebhookSecret !== undefined &&
			updateWebhookEventTypes.length >= MIN_SHARED_SECRET_LENGTH
		)
			updateDict.sharedSecret = updateWebhookSecret;
		if (
			updateWebhookEventTypes !== null &&
			updateWebhookEventTypes !== undefined &&
			updateWebhookEventTypes.length > MINARRAYLEN
		)
			updateDict.eventTypes = updateWebhookEventTypes;

		axios
			.patch(getAPI('auth', 'webhooks'), updateDict, { headers: { Authorization: `Bearer ${accessToken}` } })
			.then(() => {
				setIsUpdateLoading(false);
				setIsSuccess(true);
				setSuccessMessage('Webhook Updated Successfully');
				loadWebhook();
			})
			.catch((e) => {
				setIsUpdateLoading(false);
				setIsError(true);
				if ('data' in e.response && e.response.data !== null) setErrorMessage(e.response.data);
				else setErrorMessage('Unable to update webhook. Please try again later.');
			});
	};

	const deleteWebhook = () => {
		if (webhook === null) return;
		setIsSuccess(false);
		setIsError(false);
		setIsDeleteLoading(true);
		axios
			.delete(`${getAPI('auth', 'webhooks')}?webhook_id=${webhook.webhookId}`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			})
			.then(() => {
				history.push('/webhooks');
				setIsDeleteLoading(false);
				setIsSuccess(true);
				setSuccessMessage('Webhook Deleted Successfully');
				loadWebhook();
				closeUpdateWebhookModal();
			})
			.catch(() => {
				setIsDeleteLoading(false);
				setIsError(true);
				setErrorMessage('Unable to delete webhook. Please try again later.');
			});
	};

	const appendEventType = (eventType: webhookEventTypes) => {
		setUpdateWebhookEventTypes([...updateWebhookEventTypes, eventType]);
	};
	const removeEventType = (eventType: webhookEventTypes) => {
		const index = updateWebhookEventTypes.indexOf(eventType);
		setUpdateWebhookEventTypes([
			...updateWebhookEventTypes.slice(0, index),
			...updateWebhookEventTypes.slice(index + 1, updateWebhookEventTypes.length),
		]);
	};

	const renderWebhookInfo = () => {
		if (webhook === null) return null;
		return (
			<Modal
				isOpen={isUpdate}
				className={styles.webhook__modal}
				onRequestClose={() => {
					closeUpdateWebhookModal();
				}}
				style={{ overlay: { backgroundColor: `rgba(0, 0, 0, ${MODAL_OPACITY})` } }}
				overlayClassName={styles.modal__overlay}
				ariaHideApp={false}
			>
				<input
					type="image"
					src={XMark}
					className={styles.x__mark}
					alt="Placeholder"
					onClick={() => closeUpdateWebhookModal()}
				/>
				<form className={styles.update__form}>
					<div className={styles.update__form__header}>Update Webhook</div>

					<div className={cx(styles.field__container)}>
						<div className={cx(styles.field__label)}>Webhook URL:</div>

						<input
							type="url"
							placeholder="https://"
							value={updateWebhookUrl ?? ''}
							className={cx(styles.field__value, styles.field__input)}
							onChange={(e) => {
								if (e.target.value.length < MAX_WEBHOOK_LENGTH && !e.target.value.includes(' ')) {
									setUpdateWebhookUrl(e.target.value);
								}
							}}
						/>
					</div>
					{!webhook.active && (
						<div className={cx(styles.field__container)}>
							<div className={cx(styles.field__label)}>Reason Deactivated:</div>
							<div className={cx(styles.field__value)}>{webhook.reasonDeactivated}</div>
						</div>
					)}
					{webhook.sharedSecret !== null && (
						<div className={cx(styles.field__container)}>
							<div>
								<div className={cx(styles.field__label)}>Shared Secret:</div>
								<div
									className={cx(styles.field__label__sub)}
								>{`(Min ${MIN_SHARED_SECRET_LENGTH} Characters)`}</div>
							</div>

							<input
								type="text"
								placeholder={webhook?.sharedSecret}
								value={updateWebhookSecret ?? ''}
								className={cx(styles.field__value, styles.field__input)}
								onChange={(e) => {
									if (
										e.target.value.length < MAX_SHARED_SECRET_LENGTH &&
										!e.target.value.includes(' ')
									) {
										setUpdateWebhookSecret(e.target.value);
									}
								}}
							/>
						</div>
					)}
					{eventTypes.map((eventType: webhookEventTypes, idx: number) => (
						<div
							key={`event${idx}`}
							className={styles.radio__item__container}
							onClick={() => {
								if (!updateWebhookEventTypes.includes(eventType)) appendEventType(eventType);
								else removeEventType(eventType);
							}}
						>
							<input
								type="checkbox"
								checked={updateWebhookEventTypes.includes(eventType)}
								className={styles.radio__item}
								onChange={(e) => {
									if (e.target.checked) appendEventType(eventType);
									else removeEventType(eventType);
								}}
							/>
							<div className={styles.radio__item__label}>{eventType}</div>
						</div>
					))}
					<div className={styles.webhook__button__container}>
						<button
							type="button"
							className={cx(styles.webhook__button, styles.webhook__button__update__submit, {
								[styles.webhook__button__disabled]: isUpdateDisabled(),
							})}
							disabled={isUpdateDisabled()}
							onClick={() => {
								if (webhook !== null) updateWebhook();
							}}
						>
							{isUpdateLoading ? <img src={RingsIcon} alt="loading" /> : 'Update'}
						</button>
						<button
							type="button"
							className={cx(styles.webhook__button, {
								[styles.webhook__button__disabled]: isUpdateLoading || isDeleteLoading,
							})}
							disabled={isUpdateLoading || isDeleteLoading}
							onClick={() => {
								if (webhook !== null) deleteWebhook();
							}}
						>
							{isDeleteLoading ? <img src={RingsIcon} alt="loading" /> : 'Delete'}
						</button>
					</div>
				</form>
			</Modal>
		);
	};

	const renderTopBar = () => {
		if (webhook == null) return null;
		return (
			<div className={styles.top__bar}>
				<div
					className={styles.top__bar__back}
					onClick={() => {
						history.push('/webhooks');
					}}
				>
					Webhooks
				</div>
				<img src={PaymentBarArrow} alt="payment id" className={styles.payment__bar__arrow} />
				<div className={styles.top__bar__paymentid}>{webhook.webhookId}</div>
			</div>
		);
	};

	const renderTopSection = () => {
		if (webhook == null) return null;
		return (
			<div className={styles.topsection__root}>
				<div className={styles.topsection__header}>
					<div className={styles.topsection__header__left}>
						<div className={styles.topsection__header__label}>
							<img src={WebhookIcon} alt="webhook information" className={styles.webhook__icon} />
							<div className={styles.topsection__header__label__text}>WEBHOOK</div>
						</div>
						<div className={styles.topsection__header__url}>{webhook.webhookUrl}</div>
						<div className={styles.topsection__header__sub}>{`This webhook is for ${webhook.eventTypes.join(
							', '
						)} updates`}</div>
					</div>

					<button
						type="button"
						className={styles.topsection__header__button__container}
						onClick={() => {
							setIsUpdate(true);
						}}
					>
						<img src={DotsIcon} alt="edit / delete webhook" className={styles.topsection__header__button} />
					</button>
				</div>
				<div className={styles.topsection__info}>
					<div className={styles.topsection__field}>
						<div className={styles.topsection__label}>Status</div>
						<div
							className={cx(
								styles.topsection__value,
								styles.topsection__status,
								{ [styles.topsection__status__enabled]: webhook.active },
								{ [styles.topsection__status__disabled]: !webhook.active }
							)}
						>
							<div
								className={cx(
									{ [styles.green__circle]: webhook.active },
									{ [styles.red__circle]: !webhook.active }
								)}
							/>
							{webhook.active ? 'Enabled' : 'Disabled'}
						</div>
					</div>
					<div className={styles.topsection__field}>
						<div className={styles.topsection__label}>Listening for</div>
						<div className={cx(styles.topsection__value, styles.topsection__listen)}>
							{`${webhook.eventTypes.length} events`}
						</div>
					</div>
					<div className={styles.topsection__field}>
						<div className={styles.topsection__label}>API Version</div>
						<div className={cx(styles.topsection__value)}>Default</div>
					</div>
					<div className={styles.topsection__field}>
						<div className={styles.topsection__label}>Webhook ID</div>
						<div className={cx(styles.topsection__value)}>{webhook.webhookId}</div>
					</div>
					<div className={styles.topsection__field}>
						<div className={styles.topsection__label}>Signing Secret</div>
						<div className={cx(styles.topsection__value)}>{webhook.sharedSecret}</div>
					</div>
				</div>
			</div>
		);
		// WebhookIcon
	};

	const renderLogs = () => {
		if (webhookLogs === null || webhookLogs === undefined) return null;
		return (
			<div className={styles.logs__container}>
				<div className={styles.logs__container__title}>
					{allLogFilters.map((filt, idx) => (
						<div
							key={`filter${idx}`}
							className={cx(styles.filter__element, {
								[styles.filter__element__clicked]: logsFitler === filt,
							})}
							onClick={() => setLogsFilter(filt)}
						>
							{filt}
						</div>
					))}
				</div>
				<div className={styles.logs__container__bottom}>
					<div className={styles.logs__container__bottom__left}>
						<div className={styles.col__container}>
							<div className={cx(styles.log__col, styles.log__status)}>Status</div>
							<div className={cx(styles.log__col, styles.log__type)}>Event Type</div>
							<div className={cx(styles.log__col, styles.log__eid)}>Event Id</div>
							<div className={cx(styles.log__col, styles.log__date)}>Created</div>
							<div className={cx(styles.log__col, styles.log__date, styles.log__retry)}>Next Retry</div>
						</div>
						{webhookLogs !== null &&
							webhookLogs !== undefined &&
							webhookLogs
								.filter((d) =>
									logsFitler === 'All' ? d : logsFitler === 'Succeeded' ? d.isSuccess : !d.isSuccess
								)
								.map((log: webhookLogInterface, idx: number) => (
									<div
										className={styles.row__container}
										key={`log${idx}`}
										onClick={() => {
											if (webhookLogClicked === null) setWebhookLogClicked(log);
											else if (webhookLogClicked.webhookLogId === log.webhookLogId)
												setWebhookLogClicked(null);
											else setWebhookLogClicked(log);
										}}
									>
										<div className={cx(styles.log__row, styles.log__status)}>
											<div
												className={cx(
													styles.status__element,
													{ [styles.status__completed]: log.isSuccess },
													{ [styles.status__failed]: !log.isSuccess }
												)}
											>
												{log.isSuccess ? 'Success' : 'Fail'}{' '}
											</div>
										</div>
										<div className={cx(styles.log__row, styles.log__type)}>
											{' '}
											{log.eventStatus.toLowerCase()}{' '}
										</div>
										<div className={cx(styles.log__row, styles.log__eid)}>
											{webhookLogClicked === null
												? log.webhookLogId
												: `${log.webhookLogId.slice(0, 20)}...`}
										</div>
										<div className={cx(styles.log__row, styles.log__date)}>
											{new Date(convertDatetimeEpoch(log.datetimeWebhookCreated)).toLocaleString(
												navigator.language,
												{
													hour: '2-digit',
													minute: '2-digit',
												}
											)}
										</div>
										<div
											className={cx(styles.log__row, styles.log__date, styles.log__retry, {
												[styles.no__decoration]: log.isSuccess,
											})}
										>
											{log.isSuccess
												? '-'
												: new Date(
														new Date(log.datetimeInserted).getTime() + TIMEOFFSET
												  ).toLocaleString(navigator.language, {
														hour: '2-digit',
														minute: '2-digit',
												  })}
										</div>
									</div>
								))}
					</div>

					{webhookLogClicked !== null && (
						<div className={styles.log__container__bottom__right}>
							<WebhookLogRecord webhookLog={webhookLogClicked} />
						</div>
					)}
				</div>
			</div>
		);
	};

	return (
		<div className={styles.root}>
			<NavbarPostLogin />
			{isError && (
				<Alert severity="error" className={styles.alert__message}>
					{errorMessage}
				</Alert>
			)}
			{isSuccess && (
				<Alert severity="success" className={styles.alert__message}>
					{successMessage}
				</Alert>
			)}
			<div className={styles.root_body}>
				<Sidebar />
				<div className={styles.component__container}>
					{renderTopBar()}
					{renderTopSection()}
					{renderLogs()}
					{isUpdate && renderWebhookInfo()}
				</div>
			</div>
		</div>
	);
};

export default WebhookInformationScene;
