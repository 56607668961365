import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism'; // or another style from the styles folder
import { formatDatetime } from 'src/Libs/helper';
import { useAppSelector } from 'src/app/hooks';
import NavbarPostLogin from 'src/features/Shared/NavbarPostLogin';
import Sidebar from 'src/features/Shared/Sidebar';
import { ModelBasicInfo, selectModel } from 'src/slices/modelSlice';
import styles from './ModelScene.module.css';

const ModelScene: React.FC = () => {
	const [selectedModel, setSelectedModel] = useState<ModelBasicInfo | null>(null);
	const { modelId } = useParams<{ modelId: string }>();

	const modelState = useAppSelector(selectModel);

	useEffect(() => {
		const model = modelState.modelDataList.find((m) => m.modelId === modelId);
		if (!model) {
			return;
		}
		setSelectedModel(model);
	}, [modelId, modelState.modelDataList]);

	if (!selectedModel) {
		return (
			<div className="root_body" style={{ height: '100vh', color: 'white' }}>
				Loading...
			</div>
		);
	}

	return (
		<div className="root">
			<NavbarPostLogin />
			<div className="root_body">
				<Sidebar />
				<div className="component__container">
					<div className={styles.selected__model__container}>
						{selectedModel && (
							<div className={styles.component__body}>
								<h1>{selectedModel.modelName}</h1>
								<h2>
									{selectedModel.modelRootName !== selectedModel.modelName &&
										selectedModel.modelRootName}
								</h2>
								<h3>{selectedModel.modelPrimaryDescirption}</h3>
								<p>{selectedModel.modelDescription}</p>
								<div>
									<h4>Details:</h4>
									<br />
									<ul>
										<li>
											Model ID: <b>{selectedModel.modelId}</b>
										</li>
										<li>Platform: {selectedModel.modelPlatform}</li>
										<li>Size: {selectedModel.modelSize}</li>
										<li>Version: {selectedModel.modelVersion}</li>
										<li>Last Updated: {formatDatetime(selectedModel.lastUpdated)}</li>
									</ul>
								</div>
								<div>
									<h4>Tags:</h4>
									<br />
									<ul>
										{selectedModel.modelTags.map((tag) => (
											<li key={tag}>{tag}</li>
										))}
									</ul>
								</div>

								<div>
									<h4>Integration Code:</h4>
									<h5>Load Model</h5>

									<SyntaxHighlighter language="swift" style={okaidia}>
										{`
import NeuraML


init() {
	let config = SDKConfig(apiKey: "APIKEY", env: ENVS.DEV)
	self.neuraMLModel = NeuraMLModel(config: config, modelId: "MODELID")
}
`}
									</SyntaxHighlighter>

									<h5>Predict Function</h5>

									<SyntaxHighlighter language="swift" style={okaidia}>
										{`
self.neuraMLModel.predictOutput(from: image) ?? "Unknown Label"
`}
									</SyntaxHighlighter>
								</div>
							</div>
						)}
					</div>
					<br />
					<br />
					<br />
					<br />
				</div>
			</div>
		</div>
	);
};

export default ModelScene;
