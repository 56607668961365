/* global localStorage */
import React, { useEffect, useState } from 'react';
// modal section
import Modal from 'react-modal';
// Material-UI alerts
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import cx from 'classnames';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import SeeAdditionalIcon from 'src/assets/see-additional.svg';
// bullet icon
import XMark from 'src/assets/x-mark.svg';
import config from 'src/config';
import CreateApiKeyModal from 'src/features/Shared/CreateApiKeyModal';
import NavbarPostLogin from 'src/features/Shared/NavbarPostLogin/NavbarPostLogin';
import RevokeApiKeyModal from 'src/features/Shared/RevokeApiKeyModal';
import SceneTitle from 'src/features/Shared/SceneTitle';
import Sidebar from 'src/features/Shared/Sidebar';
import { convertDatetimeEpoch, env, formatUser, getAPI } from 'src/Libs/helper';
import { ARRAYOFFSET, MINARRAYLEN, MODAL_OPACITY } from 'src/Libs/magicConst';
import { selectCompany } from 'src/slices/companySlice';
import { developerApiKeyInterface, selectUser, setDeveloperApiKeys, setOpenRevokeModal } from 'src/slices/userSlice';

import styles from './ApiKeys.module.css';

export const ApiKeys: React.FC = () => {
	/**
	 * When api key created, same api will return full api key, which is not stored in redux (only abc*...*xyz) is stored
	 * User must store api key here
	 * Further getapiKeys calls will return apiKeys in same format and user will have to create new api key if old one is lost
	 */
	/**
	 * IMPORTANT -- one modal is local in this component and one is external
	 * Issue that this resolved --- once 1st api key was created, `noApiKeysPresent` was no longer true, so
	 * switched to other modal (without displaying the current one) right away
	 * Resolved by having this local state of the modal.
	 */
	const dispatch = useAppDispatch();
	const company = useAppSelector(selectCompany);
	const user = useAppSelector(selectUser);
	// error
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	// success
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	// loading

	// modals
	const [createApiKeyModalIsOpen, setCreateApiKeyModalIsOpen] = useState<boolean>(false);
	const [revokeApiKeyModalIsOpen, setRevokeApiKeyModalIsOpen] = useState<boolean>(false);

	const [localApiKeyRevoke, setLocalApiKeyRevoke] = useState<developerApiKeyInterface>();

	const accessToken: string =
		localStorage[
			`CognitoIdentityServiceProvider.${config[env].cognito.APP_CLIENT_ID}.${formatUser(user.userId)}.accessToken`
		];

	const createError = (errMessage: string) => {
		setIsSuccess(false);
		setSuccessMessage('');
		setIsError(true);
		setErrorMessage(errMessage);
	};
	const createSuccess = (succMessage: string) => {
		setIsError(false);
		setErrorMessage('');
		setIsSuccess(true);
		setSuccessMessage(succMessage);
	};
	const clearMessages = () => {
		setIsSuccess(false);
		setIsError(false);
		setErrorMessage('');
		setSuccessMessage('');
	};

	useEffect(() => {
		axios
			.get(getAPI('auth', 'apiKeys'), { headers: { Authorization: `Bearer ${accessToken}` } })
			.then((response) => {
				const apiKeys = response.data;

				if (apiKeys.length === MINARRAYLEN) {
					dispatch(setDeveloperApiKeys({ developerApiKeys: [] }));
				} else {
					dispatch(setDeveloperApiKeys({ developerApiKeys: apiKeys }));
				}
			})
			.catch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company.companyId]);

	const displayKeyTable = () => (
		<div className={styles.table}>
			<div className={styles.table__header}>
				<div>
					<div className={styles.table__header__title}>APIs Keys</div>
					<div className={styles.table__header__subtitle}>
						Documentation about how to implement the neuraml API can be found at https://doc.neuraml.ai
					</div>
				</div>
				<button type="button" onClick={() => setCreateApiKeyModalIsOpen(true)}>
					<div className={styles.addButton}>
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9.99996 4.16675V15.8334M4.16663 10.0001H15.8333"
								strokeWidth="1.66667"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<div>create new key</div>
					</div>
				</button>
			</div>
			<div className={styles.columns__container}>
				<div className={cx(styles.col, styles.col__apikey__name)}>Name</div>
				<div className={cx(styles.col, styles.col__apikey)}>Key</div>
				<div className={cx(styles.col, styles.col__date)}>Created</div>
			</div>
			<div>
				{user.developerApiKeys !== null &&
					user.developerApiKeys !== undefined &&
					user.developerApiKeys.length > MINARRAYLEN &&
					user.developerApiKeys.map((userApiKey: developerApiKeyInterface, index: number) => (
						<div
							key={`developer key${index}`}
							className={cx(styles.record, {
								[styles.record__last]: index === user.developerApiKeys.length - ARRAYOFFSET,
							})}
							onClick={() => {
								dispatch(setOpenRevokeModal({ openRevokeModal: 'true' }));
								setLocalApiKeyRevoke(userApiKey);
								setRevokeApiKeyModalIsOpen(true);
							}}
						>
							<div className={cx(styles.row__cell, styles.row__apikey__name)}>
								{userApiKey.apiKeyName}
							</div>
							<div className={cx(styles.row__cell, styles.row__apikey)}>{userApiKey.apiKey}</div>
							<div className={cx(styles.row__cell, styles.row__date)}>
								{new Date(convertDatetimeEpoch(userApiKey.datetimeCreated)).toLocaleString(
									navigator.language,
									{
										year: 'numeric',
										month: 'short',
										day: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
									}
								)}
								<img
									src={SeeAdditionalIcon}
									alt="see additional information"
									className={styles.additional__icon}
								/>
							</div>
						</div>
					))}
			</div>
		</div>
	);

	const displayRevokeKey = () => {
		if (user.openRevokeModal !== 'true' || localApiKeyRevoke === null || localApiKeyRevoke === undefined)
			return null;
		return (
			<Modal
				isOpen={revokeApiKeyModalIsOpen}
				className={styles.developer__modal}
				onRequestClose={() => {
					setRevokeApiKeyModalIsOpen(false);
				}}
				style={{ overlay: { backgroundColor: `rgba(0, 0, 0, ${MODAL_OPACITY})` } }}
				overlayClassName={styles.modal__overlay}
				ariaHideApp={false}
			>
				<input
					type="image"
					src={XMark}
					className={styles.x__mark}
					alt="Placeholder"
					onClick={() => setRevokeApiKeyModalIsOpen(false)}
				/>
				<RevokeApiKeyModal
					datetimeCreated={localApiKeyRevoke.datetimeCreated}
					apiKey={localApiKeyRevoke.apiKey}
					apiKeyName={localApiKeyRevoke.apiKeyName}
					internalApiKeyId={localApiKeyRevoke.internalApiKeyId}
					createError={createError}
					createSuccess={createSuccess}
					clearMessages={clearMessages}
				/>
			</Modal>
		);
	};

	const displayCreateKey = () => (
		<Modal
			isOpen={createApiKeyModalIsOpen}
			className={styles.developer__modal}
			onRequestClose={() => {
				setRevokeApiKeyModalIsOpen(false);
			}}
			style={{ overlay: { backgroundColor: `rgba(0, 0, 0, ${MODAL_OPACITY})` } }}
			overlayClassName={styles.modal__overlay}
			ariaHideApp={false}
		>
			<input
				type="image"
				src={XMark}
				className={styles.x__mark}
				alt="Placeholder"
				onClick={() => setCreateApiKeyModalIsOpen(false)}
			/>
			<CreateApiKeyModal createError={createError} createSuccess={createSuccess} clearMessages={clearMessages} />
		</Modal>
	);

	return (
		<div className={styles.root}>
			<NavbarPostLogin />

			{isError && (
				<Alert severity="error" className={styles.alert__message}>
					{errorMessage}
				</Alert>
			)}
			{isSuccess && (
				<Alert severity="success" className={styles.alert__message}>
					{successMessage}
				</Alert>
			)}
			<div className={styles.root_body}>
				<Sidebar />
				<div className={styles.component__container}>
					<SceneTitle title="API Keys" />

					{displayKeyTable()}
					{createApiKeyModalIsOpen && displayCreateKey()}
					{revokeApiKeyModalIsOpen && displayRevokeKey()}
				</div>
			</div>

			{/* <SceneTitle title="API Keys" /> */}
		</div>
	);
};

export default ApiKeys;
