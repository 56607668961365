/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

export interface ModelBasicInfo {
	modelId: string;
	modelRootName: string;
	modelName: string;
	modelPrimaryDescirption: string;
	modelDescription: string;
	isNeuramlModel: boolean;
	modelVersionId: string;
	modelPlatform: string; // TODO: SHOULD BE ARRAY OF STRING
	modelSize: string;
	modelMetadata: null | Object;
	versionDescription: string;
	modelVersion: string;
	datetimeCreated: string;
	lastUpdated: string;
	checksum: string;
	modelTags: string[];
	downloads: number;
}

export interface modelState {
	modelDataList: Array<ModelBasicInfo>;
}

const initialState: modelState = {
	modelDataList: [],
};

export const modelSlice = createSlice({
	name: 'model',
	initialState: initialState,
	reducers: {
		setModelDataList: (state, action) => {
			state.modelDataList = action.payload?.modelDataList;
		},
	},
});

export const { setModelDataList } = modelSlice.actions;

export const selectModel = (state: RootState) => state.model;

export default modelSlice.reducer;
