import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { lightfair } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { APILogsInterface } from 'src/Libs/types';

import styles from './APILogRecord.module.css';

interface Props {
	apiLog: APILogsInterface;
}

export const APILogRecord: React.FC<Props> = ({ apiLog }) => {
	const renderMetaData = () => (
		<div className={styles.metadata__container}>
			<div className={styles.record}>
				<div className={styles.record__label}>Status</div>
				<div className={styles.record__value}>
					{' '}
					{`${apiLog.responseStatusCode} ${
						apiLog.responseStatusCode >= 200 && apiLog.responseStatusCode < 300 ? 'OK' : 'ERR'
					}`}
				</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>ID</div>
				<div className={styles.record__value}>{apiLog.requestId}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>Time</div>
				<div className={styles.record__value}>{apiLog.datetimeInserted}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>IP address</div>
				<div className={styles.record__value}>{apiLog.sourceIp !== null ? apiLog.sourceIp : ''}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>API Version</div>
				<div className={styles.record__value}>{1.1}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>Idempotency</div>
				<div className={styles.record__value}>{apiLog.requestId}</div>
			</div>
			<div className={styles.record}>
				<div className={styles.record__label}>Status Code</div>
				<div className={styles.record__value}>{apiLog.responseStatusCode}</div>
			</div>
		</div>
	);

	const formatString = (string: string | null) => {
		if (string === null) return '';
		return string.replace('{', '{\n ').replace(/, /g, ',\n ').replace('}', '\n}').replace(/'/g, '"');
	};

	return (
		<div className={styles.container}>
			<div className={styles.webhook__url}> POST &nbsp; {apiLog.rawPath}</div>
			{renderMetaData()}

			<div className={styles.webhook__url}>Response Body </div>
			<div className={styles.syntax__container}>
				<SyntaxHighlighter language="json" style={lightfair} wrapLongLines showLineNumbers>
					{formatString(apiLog.responseMessage)}
				</SyntaxHighlighter>
			</div>
		</div>
	);
};

export default APILogRecord;
