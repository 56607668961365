import React from 'react';
import ForgotPassword from 'src/features/Auth/ForgotPassword';

import './ForgotPasswordScene.module.css';

function ForgotPasswordScene() {
	return (
		<div>
			<ForgotPassword />
		</div>
	);
}

export default ForgotPasswordScene;
