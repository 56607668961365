import React from 'react';
import Logo from 'src/assets/logo/logo_black.svg';

import styles from './NotFound.module.css';

export const NotFound: React.FC = () => (
	<div>
		<img src={Logo} className={styles.logo} alt="asset" />
		<h1> Oops, the page you are looking for could not be found on this site. </h1>
	</div>
);

export default NotFound;
